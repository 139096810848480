<template>
    <vue-basic-alert :duration="300" :closeIn="10000" ref="alert" />
    <div class="Shop-container">
        <div class="row1">
           <router-link to="/asesdash" class="close-btn" > Close </router-link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <h1>Payment Details of Properties</h1>
       </div>
       <div class="Shop-form-container">
           <form id="addShopsForm"  novalidate autocomplete="off">
                <div class="heading">
                    <b><h3>Search Property</h3></b>
                </div>
                <h5><b>Select by Category</b></h5>
               <div class="row">
                <div class="col-sm-3">
                    
                   <label for="Splace">Ward
                   </label>
                       <div><select v-model="CatObj.ward" id="ward" class="form-control" >
                            <option value="" selected disabled>
                                 Choose
                             </option>
                            <option v-for="m in savedWards" :key="m.ward_id" id="Hid"  
                            v-bind:value =m.ward_id>
                               <div>{{m.ward }}</div> 
                            </option>
                        </select>
                     </div> 
  
                   <p class="error-mess" v-if="errObj.wardErr.length > 0">{{ errObj.wardErr[0] }}</p>
               </div>
               <div class="col-sm-3">
                   <label for="street">Street
                   </label>
                       <div><select v-model="CatObj.street" id="street" class="form-control" >
                            <option value="" selected disabled>
                                 Choose
                             </option>
                            <option v-for="m in savedStreets" :key="m.street_id" id="Hid"  
                            v-bind:value =m.street_id>
                               <div>{{m.street_name }}</div> 
                            </option>
                        </select>
                     </div> 
  
                   <p class="error-mess" v-if="errObj.stretErr.length > 0">{{ errObj.stretErr[0] }}</p>
               </div>
               <div class="col-sm-3">
                   <label for="cat">Main Category
                   </label>
                       <div><select v-model="CatObj.mainc" id="cat" class="form-control" >
                            <option value="" selected disabled>
                                 Choose
                             </option>
                            <option v-for="m in maincate" :key="m.cat_id" id="Hid"  
                            v-bind:value =m.cat_id>
                               <div>{{m.category_name }}</div> 
                            </option>
                        </select>
                     </div> 
  
                   <p class="error-mess" v-if="errObj.maincErr.length > 0">{{ errObj.maincErr[0] }}</p>
               </div>
               <div class="Addbtn col-sm-3">
                   <label for="s">
                   </label>
                   <input type="button" value="Search" id="Addpay" class=" btn" @click="SearchByCat()"  /> 
               </div>
               </div>
               <div class="row">                    
                      <div class="col-sm-3">
                          <h5><b>search by property ID</b></h5>
                          <label for="usedn"> Property ID:
                          </label>
                          <div>
                          <input 
                          v-model="CatObj.propsearch" 
                          id="serchprop" 
                          list="sabaPropList" 
                          class="form-control"
                          placeholder="Choose or type a property ID"
                          />
                          
                          <datalist id="sabaPropList">
                          <option 
                              v-for="p in sabaProp" 
                              :key="p.property_id" 
                              :value="p.property_id">
                          </option>
                          </datalist>
                      </div>
                      </div>
                      <div class="Addbtn col-sm-3">
                     <br>
                     <input type="button" value="Search" id="secbtn" class=" btn" @click="SearchById()"  /> 
                      </div>
                  </div>
                  
                <!-- <div class="row">                    
                    <div class="col-sm-3">
                        <h5><b>Select by property ID</b></h5>
                        <label for="usedn"> Property ID:
                        </label>
                        <div>
                          <input type="text" id="serchprop" class="form-control" v-model="CatObj.propsearch" /> 
                          <select v-model="CatObj.propsearch" id="serchprop" class="form-control" >
                            <option value="" selected disabled>
                                        Choose
                                    </option>
                                    <option v-for="p in sabaProp" :key="p.property_id" id="Hid"  
                                    v-bind:value =p.property_id>
                                    <div>{{p.property_id }}</div> 
                                    </option>
                                </select> 
                            < <p class="error-mess" v-if="errObj.propErr.length > 0">{{ errObj.propErr[0] }}</p>
                        </div>
                      </div>
                      <div class="Addbtn col-sm-3"></div>
                      <br>
                      <input type="button" value="Search" id="secbtn" class=" btn" @click="SearchById()"  /> 
                    </div> -->
              <!-- </div> -->
            
           <br>
           <div v-if="showtbl" >
           <div   style="width: 100%; text-align: center;">
                <h3>Select a Property to Add PIV</h3>
            </div>
           <div class="row">
            <table style="width: 100%; text-align: left; font-size: small; border-width: 1px; border-style: solid; border-color: #130f40; border-collapse: collapse; margin-left: auto; margin-right: auto;">
               <thead>
                   <tr>   
                        <th>Index</th>
                       <th>Property ID</th>
                       <th>Assessment Num</th>
                       <th>Address</th>
                       <th>Rate</th>
                       <th>Select</th>
                   </tr>
               </thead>
               <tbody>
                   <tr v-for=" (s,index) in paginatedData" :key="index">
                    <td>{{ (currentPage - 1) * 10 + index + 1 }}</td>
                       <td>{{ s.property_id  }}</td>
                       <td>{{ s.asses_num  }}</td>
                       <td>{{ s.prop_address }}</td>
                       <td>{{ s.prop_rate }}</td>
                       <td> <input
                                type="radio"
                                name="propertySelection"
                                :value="s.property_id"
                                v-model="selectedProperty"
                                 @change="populateCustomerDetails(s.property_id)"
                            /></td>
                   </tr>
               </tbody>
           </table>
           </div>
           <button class="fas fa-backward" @click="prevPage($event)" :disabled="currentPage === 1"></button>
             <span>{{ currentPage }} / {{ totalPages }}</span>
            <button class="fas fa-forward" @click="nextPage($event)" :disabled="currentPage === totalPages"></button>
        </div>
        <!-- <div v-if="selectedProperty" style="margin-top: 10px; text-align: center;">
            <p>Selected Property ID: {{ selectedProperty }}</p>
         </div> -->
           </form>
           <!-- piv -->
           <div v-if="showtblpro">
            <div class="register-container">
           <div class="register-form-container">
           <form id="userForm"  novalidate autocomplete="off">
            
               <div class="heading">
                   <h3>Payment Details </h3>
               </div>
               <h4>Assesment Property Payment Details - {{ new Date(this.serverDate).getFullYear() }}</h4>
                   <div class="row">
                    <div class="col-sm-8">
                        <label for="cName">Name: {{ cusObj.name }}</label>
                        <input name="cName" id="cName" class="form-control" v-model="cusObj.name" rows="2" hidden>
                    </div>
                       <div class="col-sm-4">
                           <label for="cAddress">Address: {{ cusObj.address }}</label>
                            <input name="cAddress"  id="cAddress" class="form-control" v-model="cusObj.address" hidden >
                       </div>
                   </div>
                  <div class="form-group" >
                  <table class="ttable"  >
                  <thead>
                    <tr>
                      <th>Quarter</th>
                      <th>Payable Amount</th>
                      <th>Paid Amount</th>
                      <th>Fine</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="p in propDetails" :key="p.property_id">
                  <!-- First Quarter -->
                  <tr>
                    <td style="text-align: left;"><b>First Quarter Amount</b></td>
                    <td>{{ p.q_one_val }}</td>
                    <td>{{ paymentData[p.property_id] && paymentData[p.property_id][1] ? paymentData[p.property_id][1][0] : 'Loading...' }}</td>
                    <td>{{ paymentData[p.property_id] && paymentData[p.property_id][1] ? paymentData[p.property_id][1][1] : 'Loading...' }}</td>
                  </tr>
  
                  <!-- Second Quarter -->
                  <tr>
                    <td style="text-align: left;"><b>Second Quarter Amount</b></td>
                    <td>{{ p.q_two_val }}</td>
                    <td>{{ paymentData[p.property_id] && paymentData[p.property_id][2] ? paymentData[p.property_id][2][0] : 'Loading...' }}</td>
                    <td>{{ paymentData[p.property_id] && paymentData[p.property_id][2] ? paymentData[p.property_id][2][1] : 'Loading...' }}</td>
                  </tr>
  
                  <!-- Third Quarter -->
                  <tr>
                    <td style="text-align: left;"><b>Third Quarter Amount</b></td>
                    <td>{{ p.q_three_val }}</td>
                    <td>{{ paymentData[p.property_id] && paymentData[p.property_id][3] ? paymentData[p.property_id][3][0] : 'Loading...' }}</td>
                    <td>{{ paymentData[p.property_id] && paymentData[p.property_id][3] ? paymentData[p.property_id][3][1] : 'Loading...' }}</td>
                  </tr>
  
                  <!-- Fourth Quarter -->
                  <tr>
                    <td style="text-align: left;"><b>Fourth Quarter Amount</b></td>
                    <td>{{ p.q_four_val }}</td>
                    <td>{{ paymentData[p.property_id] && paymentData[p.property_id][4] ? paymentData[p.property_id][4][0] : 'Loading...' }}</td>
                    <td>{{ paymentData[p.property_id] && paymentData[p.property_id][4] ? paymentData[p.property_id][4][1] : 'Loading...' }}</td>
                  </tr>
                  </template>
  
  
                  </tbody>
                  </table>
  
                  <br>
                  </div>   
                   <!--.------------------------------- payment history-------------------------------------->
                  <div id="PrintContent">           
                  <div class="heading">
                   <h3>Payment History </h3>
               </div>
                   
           

               <div class="form-group">
               <table class="ttable" style="width: 98%; text-align: center; border-width: 1px; border-style: solid; border-color: #130f40; border-collapse: collapse;">
               <thead >
                   <tr>   
                       <th style=" text-align: center; border-width: 1px; border-style: solid; border-color: #130f40; border-collapse: collapse;">Quarter</th>
                       <th style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40; border-collapse: collapse;">Discount Given(Rs.)</th>
                       <th style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40; border-collapse: collapse;">Fine Amount (Rs.)</th>
                       <th style=" text-align: center; border-width: 1px; border-style: solid; border-color: #130f40; border-collapse: collapse;">Paid Amount(Rs.)</th>
                       <th style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40; border-collapse: collapse;">Paid Date</th>
                       
                   </tr>

               </thead>
               <tbody>
                   <tr v-for=" r in allreceipt" :key="r.id">
                        <td style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40; border-collapse: collapse;">{{ r.quarter_num }}</td>
                       
                       <td style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40; border-collapse: collapse;">{{ $formatNumber(r.discount_given) }}</td>
                       <td style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40; border-collapse: collapse;"> {{ $formatNumber(r.fine_amount) }}</td>
                       <td style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40; border-collapse: collapse;">{{ $formatNumber(r.paid_amount) }} </td>
                       <td style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40; border-collapse: collapse;">{{ formattedDategiven(r.paid_date) }}</td>
                      
                   </tr>
               </tbody>
           </table>
           </div>
          </div> 
          <br>
          <div class="form-group">
            <div class="row">
              <div class="col-md-6">
            <input type="button" value="Print" class="btn" @click="fetchDataAndPrint" />&nbsp;&nbsp;&nbsp;
            <input  type="button" id="excebtn" value="To Excel" class="btn"  @click="exportTableToExcel" :disabled="disableButton" />
            </div>
            </div>
            
          </div>
           </form>
          </div> 
           
       </div>
       </div>
  </div>
  </div>
  <!-- end -->
  </template>
  
  <script>
  import axios from "axios";
  import VueBasicAlert from 'vue-basic-alert'
  import { mapMutations } from "vuex";
  import { mapState } from "vuex";
  import * as XLSX from 'xlsx';
  
  export default {
  
    name: "AssessPIV",
  
    data() {
       return {
           nic:"",
           sabha: "",
           userLevel:"",
           CatObj : { ward:"", street:"",  mainc:"" ,propsearch:""},
           errObj:{ wardErr:[], stretErr:[],maincErr:[]},
           savedWards:[],
           savedStreets:[],
           itemsPerPage: 10,
            currentPage: 1,
            maincate:[],
            sabaProp:[],
            propertydetails:[],
            showtbl:false,showtblpro:false,
            selectedProperty: null,
            cusObj:{name:"",address:"",headid:"",descr:"",arre:"",relper:"0",fq:"",sq:"",tq:"",foq:"",fqDiscount:"",sqDiscount:"",yearlyval:"",discval:"",payamountqaurter:"",finevot:"",arreasvot:""},
            cusErrObj:{nameErr:[],addErr:[],headidErr:[],descrErr:[],monthendErr:[],iheadErr:[],fineheadErr:[],arreheadErr:[],amountpaidErr:[]},
            amountpaid:0,
            ownerdetails:[],
            sabhaIncomeHead:[],
            propDetails:[],
            paymentData: {}, // Store paid amount and fine for each property and quarter
            response:[],
            responseData:[],
            Relchecked: false,
            propertyde:[],
            Annualchecked:false,
            errorMessage: "",
            qonecheck:false,
            qOneErrorMessage:"",
            qtwoErrorMessage:"",
            qtwocheck:false,
            qthreeErrorMessage:"",
            qthrecheck:false,
            qfourerrorMessage:"",
            qfourcheck:false,
            allreceipt:[],
            arrearsAmount:0,
            pamentArreas:[],
            isReadonly: true,
            notpaidQuaters:[],
            assnum:'',
            asspropId:0,totalqtpayment:0,
            descriptionInput: '',
            assdiscount:0,
            usedNum:'',
            serverDate:'',disableButton:true,
       }; 
   },
   created() {
       this.getDataFromSessionStorage()
  
   },
  
   computed:{
       ...mapState(["user"]),
       amountpaidget(){
        let ampaid=0;
          if (parseFloat(this.cusObj.arre) > 0) {
            ampaid=parseFloat(this.cusObj.arre).toFixed(2)
          } 
          else {
            ampaid=parseFloat(this.cusObj.payamountqaurter).toFixed(2)
          }
            
          return ampaid
       },
     
  
    paginatedData() {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        
        // Check if this.data is an array before using slice method
        if (Array.isArray(this.propertydetails)) {
        return this.propertydetails.slice(startIndex, endIndex);
        } else {
        // Return empty array or handle loading state
        return [];
        }
  },

   },
   async mounted() {
    try {
        this.maincate = (await axios.get('/maincat/')).data
           //wards
           this.savedWards= (await axios.get('/wdbys/'+this.sabha)).data;
           //streets
           this.savedStreets = (await axios.get('/stbys/'+this.sabha)).data;
  
    } catch (error) {
        console.error("Error fetching data", error);
    }
  
    await this.loadPaymentData();
    // await this.loadArrears();
  
  },
  watch: {
    propDetails: {
    handler(newVal) {
      if (Array.isArray(newVal) && newVal.length > 0) {
        newVal.forEach(async (p) => {
          try {
            await this.loadPaymentData(p.property_id);
          } catch (error) {
            console.error(`Failed to load payment data for Property ID: ${p.property_id}`, error);
          }
        });
      }
    },
    immediate: true,
    deep: true
  },

   totalAmount: 'updatePayAmountQuarter',
    totalFine: 'updatePayAmountQuarter',
    descriptionText(newVal) {
      this.descriptionInput = newVal;
    },
    // amountpaidget(newVal) {
    //   this.amountpaid = newVal;
    // }
  },
   methods: {
       ...mapMutations(["setUser"]),
  
 
       async getDataFromSessionStorage(){
           const data = JSON.parse(sessionStorage.getItem('userData'))
           if(data){
                           this.nic = data.nic 
                           this.sabha = data.sabha
                           this.userLevel =data.userLevel
                       }
                try{
                this.sabaProp = (await axios.get('/props/' +this.sabha)).data                       
            } catch (error) {
                console.error("Error fetching data", error);
            }
           this.maincate = (await axios.get('/maincat/')).data
           //wards
           this.savedWards= (await axios.get('/wdbys/'+this.sabha)).data;
           //streets
           this.savedStreets = (await axios.get('/stbys/'+this.sabha)).data;
           //incomeheads
           let Sabhadata = await axios.get('/subheads/'+ this.nic +"/"+ this.sabha)
           this.sabhaIncomeHead = Sabhadata.data
        //    this.allreceipt =  (await axios.get('/ownpiv/'+ this.nic +"/"+ this.sabha)).data
           try {
                      const response = await axios.get('/getserverdate'); // Ensure correct API route
                      this.serverDate = response.data.serverDate; // Ensure correct response key
                      console.log(response.data);
                      // this.formatcurrentDate=this.getCurrentDate(this.serverDate);
                  } catch (error) {
                      console.error("Error fetching server date:", error);
                  }
  
       },
       getWardName(ward_id) {
        const ward = this.savedWards.find(m => m.ward_id === ward_id);
        return ward ? ward.ward : 'Unknown';  // Return 'Unknown' if ward_id is not found
    },
    getStreetName(street_id) {
        const street = this.savedStreets.find(m => m.street_id === street_id);
        return street ? street.street_name : 'Unknown';
    },
    getCategoryName(cat_id) {
        const category = this.maincate.find(m => m.cat_id === cat_id);
        return category ? category.category_name : 'Unknown';
    },
  
  nextPage(event) {
  if (this.currentPage < this.totalPages) {
    this.currentPage++;
  }
  event.preventDefault();
  },
  prevPage(event) {
  if (this.currentPage > 1) {
    this.currentPage--;
  }
  event.preventDefault();
  },
  //for saving piv
 
    formattedDate() {
            let date = new Date(this.serverDate)
            const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
          },
          formattedDategiven(da) {
            
            let date = new Date(da);
            if (da === null || da === undefined) {
                console.error('Received null or undefined date');
                return ''; // Return a fallback value
            }
            // Check if the date is valid
            if (isNaN(date)) {
                console.error('Invalid date:', da);
                return 'Invalid Date';
            }
        
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const fdate = `${year}-${month}-${day}`;
        
            const options = { timeZone: 'Asia/Colombo', year: 'numeric', month: '2-digit', day: '2-digit' };
            return fdate.toString('en-US', options);
        
        },
  //fectch both
    async fetchData(url) {
        try {
            const response = await axios.get(url);
            this.propertydetails = response.data;
            if (this.propertydetails.length > 0) {
            this.showtbl = true;
            } else {
            this.message = "No such record";
            this.showtbl = false;
            }
        } catch (error) {
            console.error("Error fetching details:", error);
            this.message = "Failed to load details. Please try again later.";
        }
        },
  
       resetCheckErr: function () {
        this.errObj.wardErr =[];
        this.errObj.stretErr=[];
        this.errObj.maincErr = [];
       },
       checkEmptyErr: function () {
           for (var typeErr in this.errObj) {
               if (this.errObj[typeErr].length != 0) {
                   return false;
               }
           }
           return true;
       },
       checkForm: function () {
           this.resetCheckErr();
           if (!this.CatObj.ward) {
               this.errObj.wardErr.push("required");
           }
           if (!this.CatObj.street) {
               this.errObj.stretErr.push("required");
           }
           // main categories
           if (!this.CatObj.mainc) {
               this.errObj.maincErr.push("required");
           }
       },
       async SearchByCat(e) {
            this.checkForm();
            if (!this.checkEmptyErr()) {
                e.preventDefault();
            } else {
                this.showtbl = false;
                this.showtblpro = false;
                
            this.message = ""; // Clear any previous messages
            // this.propertydetails = []; // Clear previous data
            this.CatObj.propsearch=""
            this.selectedProperty= null
                const url = `/proplist/${this.sabha}/${this.CatObj.ward}/${this.CatObj.street}/${this.CatObj.mainc}`;
                await this.fetchData(url);
                this.allreceipt = [];
                this.disableButton = false;
            }
             
        },
    async SearchById() {
      this.allreceipt =[];
          this.showtbl = false;
          this.showtblpro = false;
          this.disableButton = false;
        this.message = ""; // Clear any previous messages
        this.propertydetails = []; // Clear previous data
        this.CatObj.ward="",
        this.CatObj.street="",
        this.CatObj.mainc="",
        this.selectedProperty= null
        this.cusObj.arre=""
        //removing quarter payments text box values
   
    this.allreceipt = [];
    try {
        const url = `/sabapropid/${this.sabha}/${this.CatObj.propsearch}`;
        
        // Fetch data from API
        const response = await axios.get(url);
  
        if (response.data.length > 0) {
        this.propertydetails = response.data;
        this.showtbl = true;
        } else {
        this.message = "No such record";
        this.showtbl = false;
        
        }
        // this.allreceipt = (await axios.get('/ownpiv/' + this.nic + "/" + this.sabha)).data;
    } catch (error) {
        console.error("Error fetching details:", error);
        this.message = "Failed to load details. Please try again later.";
        this.showtbl = false;
        this.showtblpro = false;
        this.qfourcheck=false;
          
    }
    },
    
    async populateCustomerDetails(property) {
  try {
    
    const response = await axios.get(`/prownerdetails/${property}/${this.sabha}`);
  
    if (response.data && response.data.length > 0) {
      this.ownerdetails = response.data;
  
      // Join multiple names into a single string (e.g., comma-separated)
      this.cusObj.name = this.ownerdetails.map(owner => owner.cus_name).join(', ') || "";
  
      // Assign the first address found
      this.cusObj.address = this.ownerdetails[0].prop_address || "";
  this.assnum=this.ownerdetails[0].asses_num;
  this.asspropId=this.ownerdetails[0].property_id;
  
  
    } else {
      console.warn("No owner details found.");
      this.$refs.alert.showAlert('error', 'No owner details found');
      this.cusObj = { name: "", address: "" }; // Clear form
      this.message = "No owner details available.";
    }
  
    //get property payment details to show in the table
    this.propDetails =  (await axios.get('/sabapropid/'+ this.sabha +"/"+ property)).data
    this.cusObj.arre=this.propDetails[0].arrears_val;
    this.cusObj.fq=this.propDetails[0].q_one_val;
    this.cusObj.sq=this.propDetails[0].q_two_val;
    this.cusObj.tq=this.propDetails[0].q_three_val;
    this.cusObj.foq=this.propDetails[0].q_four_val;
    this.usedNum=this.propDetails[0].used_num;

    this.showtblpro=true;

    this.allreceipt =  (await axios.get('/payhistory/'+ property +"/"+ this.sabha)).data
    
  
  } catch (error) {
    console.error("Error fetching owner details:", error);
    this.message = "Failed to load owner details. Please try again later.";
  }
  // this.loadPaymentData()
  
  },
  
  async loadPaymentData(pid) {
    const currentYear = new Date(this.serverDate).getFullYear();
  
    try {
      // Check for annual payment (Quarter 5)
      const annualResponse = await axios.get(`/pqpayview/${pid}/${this.sabha}/5/${currentYear}`);
      const annualResult = annualResponse.data[0];
  
      if (annualResult) {
        // If annual payment exists, mark all quarters as "Paid"
        this.paymentData[pid] = {
          '1': ["Paid", "0.00"],
          '2': ["Paid", "0.00"],
          '3': ["Paid", "0.00"],
          '4': ["Paid", "0.00"],
        };
        return this.paymentData[pid];
      }
  
      // If no annual payment, check individual quarters
    
      for (let quarter = 1; quarter <= 4; quarter++) {
    try {
      const response = await axios.get(`/pqpayview/${pid}/${this.sabha}/${quarter}/${currentYear}`);
      const result = response.data?.[0]; // Ensure result is correctly accessed
  
      if (!this.paymentData[pid]) this.paymentData[pid] = {}; // Initialize pid entry if it doesn't exist
  
      if (result && result.paid_amount) {
        // Check if paid_amount exists and is valid
        this.paymentData[pid][quarter] = ["Paid",  parseFloat(result.fine_amount || 0).toFixed(2)];
      } else {
        // Mark as not paid if result is falsy or paid_amount is absent
        this.paymentData[pid][quarter] = ["Not Paid", "Not Paid"];
      }
    } catch (error) {
      console.error(`Error loading data for Quarter ${quarter}:`, error);
      this.paymentData[pid][quarter] = ["Error", "Error"];
    }
  }
  
  
      return this.paymentData[pid];
    } catch (error) {
      console.error('Error loading payment data:', error);
      if (!this.paymentData[pid]) this.paymentData[pid] = {};
      for (let quarter = 1; quarter <= 4; quarter++) {
        this.paymentData[pid][quarter] = ["Error", "Error"];
      }
      return this.paymentData[pid];
    }
  },
  fetchDataAndPrint() {
  
  const prtHtml = document.getElementById('PrintContent').innerHTML;
  
  // Open the print window
  // const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,status=0');
  const WinPrint = window.open('', '', '');
  WinPrint.document.write(`
  ${prtHtml}
  `);
  
  WinPrint.focus();
  WinPrint.print();
  WinPrint.close();
  },
  exportTableToExcel() {
      // Get the HTML table element
      const table = document.getElementById('PrintContent');
      
      // Convert the table to a worksheet
      const ws = XLSX.utils.table_to_sheet(table);
  
      // Create a new workbook and append the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
      // Generate an Excel file and trigger download
      XLSX.writeFile(wb, 'ExportedContent.xlsx');
    }

  
  // }
  
   },
   components: {
        VueBasicAlert
    }
  };
  
  </script>
  
  <style scoped>
  .relief-row {
  display: flex;
  align-items: center;
  gap: 10px; /* Adds spacing between items */
  }
  
  .relief-row input[type="text"] {
  width: auto;
  /* box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05); */
   border: 0.1rem solid rgba(0, 0, 0, 0.2);
   padding: 0.6rem;
   border-radius: 0.5rem;
   background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
    font-size: 1.3rem;
    color: #130f40;
  }
  
  .relief-row input[type="button"] {
  width: auto;
  background-color: #022e2a;
  color:#fff;
  padding: 8px;
  border-radius: 8px;
  
  }
  .ttable {
     
     width: 100%;
     border-width:2px;
     border-color : #130f40;
     /* border-style: solid; */
     font-size: 1.2rem;
     text-align: center;
     /* background-color :white; */
     /* padding-left: 3rem; */
      /* height: 3rem; */
      padding: 2%;
  }
  th  {
     text-align: center;
  }
  .ttable, th, td {
  border: 1px solid;
  }
  .row1 {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
  .close-btn {
  /* align-items: right; */
  background-color:rgb(121, 23, 12);
  border: none;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  color: #f8f4f4;
  transition: color 0.3s ease, transform 0.2s ease;
  padding: 8px;
  border-radius: 8px;
  width:6%;
  height: 4%;
  text-align: center;
  }
  
  .close-btn:hover {
  color: rgb(220, 18, 18);
  transform: scale(1.2);
  background-color:  #022e2a; 
  }
  
  .close-btn:focus {
  outline:none; /* Remove default focus outline */
  }
  input[type="button"] {
   background: none;
   color: inherit;
   border: none;
   padding: 0;
   font: inherit;
   cursor: pointer;
   outline: inherit;
  }
  /* shroff form container */
  .Shop-container {
   background-color: #ffffff09;
   min-height: 90vh;
   padding: 1rem 2%;
   font-size: 16px;
  }
  
  /* ongoing */
  .Shop-container .Shop-form-container {
   background: #fff;
   /* width: 100% !important; */
   
  }
  
  .Shop-container .Shop-form-container form {
   /* position: relative;
   left: 40%;
   transform: translate(-50%, 0%); */
   /* max-width: 70rem; */
   width: 100%;
   box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
   border: 0.1rem solid rgba(0, 0, 0, 0.2);
   padding: 2rem;
   border-radius: 0.5rem;
   animation: fadeUp 0.4s linear;
   
  }
  
  .Shop-container .Shop-form-container form h3 {
   padding-bottom: 1rem;
   font-size: 2rem;
   text-transform: uppercase;
   color: #130f40;
   margin: 0;
  }
  
  .Shop-container .Shop-form-container form .form-control {
    margin: 0.7rem 0;
   border-radius: 0.5rem;
   background: #f7f7f7;
   /* padding: 2rem 1.2rem; */
    font-size: 1.3rem; 
   color: #130f40;
   text-transform: none;
   width: 100%;
   border: margin-bottom; 
  } 
  
  .Shop-container .Shop-form-container form label {
    font-size: 1.2rem;
   margin: 0;
   padding: 0;
   float:left;
  }
  
  .Shop-container .Shop-form-container form span {
   font-size: 18px;
   padding-left: 5px;
   padding-right: 40px;
  }
  
  .Shop-container .Shop-form-container form .btn {
   /* margin: 1rem 0; */
   width: 25%;
   text-align: center;
   font-size: small;
   background-color : #032a2c;
    height: 3rem;
    color: #fffbfb;
  
   
  }
  /* .Shop-container .Shop-form-container form .table {
   margin: 1rem 0;
   width: 100%;
   text-align: center;
   font-size: small;
   background-color : #e9e4e9;
    height: 3rem;
   
  } */
  .Shop-container .Shop-form-container form .Addbtn {
   /* margin: 1rem 0; */
   width: 5rem;
   /* height: 2rem; */
   /* text-align: center; */
   /* background-color : #af74a7; */
   /* vertical-align: bottom; */
   /* align-items: bottom; */
   padding-top: 1.9rem;
   padding-left: 3rem;
   
  }
  
  .Shop-container .Shop-form-container form p {
   padding-top: 1rem;
   font-size: 1.3rem;
   color: rgb(243, 47, 47);
   margin: 0;
  }
  
  .Shop-container .Shop-form-container form p a {
   color: #27ae60;
  }
  
  .Shop-container .Shop-form-container form p a:hover {
   color: #130f40;
   text-decoration: underline;
  }
  
  .Shop-container .Shop-form-container form .form-group {
   margin: 0;
   font-size: 1.2rem;
  }
  
  .Shop-container .Shop-form-container form .form-group .error-mess {
   font-size: 1.5rem;
   position: relative;
   color: rgb(243, 47, 47);
   margin: 0;
   /* padding: 2rem 1.2rem; */
  }
  /* second form */
  .register-container {
    padding-top: 1.0rem;
    font-size: 16px;
     /* min-height: 72.3vh; */
  }
  
  .register-container .register-form-container {
    background: #fff;
  
  }
  
  .register-container .register-form-container form {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    /* max-width: 98rem; */
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 2rem;
    border-radius: 0.5rem;
    animation: fadeUp 0.4s linear;
  }
  
  .register-container .register-form-container form h3 {
    padding-bottom: 0.5rem;
    font-size: 1.9rem;
    text-transform: uppercase;
    color: #130f40;
    /* margin: 0; */
    text-align: center;
    
  }
  
  .register-container .register-form-container form .form-control {
    margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
    font-size: 1.3rem;
    color: #130f40;
    text-transform: none;
    width: 100%;
    border: margin-bottom; 
  }
  
  .register-container .register-form-container form .form-radio {
    margin: 2rem 0%;
    border-radius: 0.5rem;
    background: #f7f7f7;
   padding-bottom: 1rem;
    font-size: 10rem;
    color: #130f40;
  
    width: 10%;
    border: none;
    margin-left: 10%;
  }
  .register-container .register-form-container form label {
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
  }
  
  .register-container .register-form-container form span {
    font-size: 25px;
    /* padding-left: 5px;
    padding-right: 40px; */
    text-align: center;
    color: #022e2a;
  }
  .register-container .register-form-container form .form-group  .error-mess {
    font-size: 1rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
    width: 90%;
  
  } 
  .register-container .register-form-container form .bbtn {
   margin: 1% 0;
   width: 100%;
   text-align: center;
   font-size: small;
   background-color : #032a2c;
   color: #f7f7f7;
    height: 3rem;
    border-radius: 8px;
  }
  .register-container .register-form-container form .btn {
       background: rgb(223, 80, 14);;
       color: rgb(240, 234, 231);
       border: 1rem;
       padding: 1rem;
       font: inherit;
       cursor: pointer;
       outline: 1rem;
       text-align: center;
      
   }
  
  </style>