<template>
  <div class="admin-container">

      <div class="home-main">
      
          <div class="content">
              <span><b>Assessment Tax Dashboard </b></span>
          
          <br>
          {{user.emp_name}} 
          </div>
      </div>
      <!-- <div class="button-grid">
      <router-link to="/adward" class="upbtn">Wards/Streets</router-link>
      <router-link to="/ascat" class="upbtn">Categories</router-link>
      <router-link to="/asrate" class="upbtn">Rates</router-link>
      <router-link to="/asproperty" class="upbtn">Properties</router-link>
      <router-link to="/owners" class="upbtn">Ownership</router-link>
      <router-link to="/proptrans" class="upbtn">Transfer</router-link>
</div> -->
<div class="card-grid">
  <router-link
    v-for="(card, index) in cards"
    :key="index"
    :to="card.link"
    class="card"
    :style="{ backgroundColor: card.bgColor, color: card.textColor }"
  >
    <div class="card-content">
      <h3>{{ card.title }}</h3>
      <p style="font-size: 1.5rem;">{{ card.description }}</p>
    </div>
  </router-link>
</div>
</div>
</template>


<script>
// import axios from 'axios';
import { mapState, mapMutations } from "vuex";
export default {
  name: 'AssessDashboard',

  
  data() {
      return {
        cards: [
      {
        title: "Wards/Streets",
        description: "Add Wards and Sreets of your Institute here",
        link: "/adward",
        bgColor: "#d8dfe5", // AliceBlue
        textColor: "#000080", // Navy
      },
      // {
      //   title: "Categories",
      //   description: "Add Assessment Sub categories of your Institute here",
      //   link: "/ascat",
      //   bgColor: "#faebd7", // AntiqueWhite
      //   textColor: "#8b0000", // DarkRed
      // },
      {
        title: "Rates",
        description: "Update Warrant Cost Rate by Selected ward, Street and category",
        link: "/asrate",
        bgColor: "#e0dbed",
        textColor: "#000000", 
      },
      {
        title: "Properties",
        description: "Enter property details of your Institute here",
        link: "/asproperty",
        bgColor: "#e5cdca", // PapayaWhip
        textColor: "#426484", // DarkSlateGray
      },
      // {
      //   title: "Ownership",
      //   description: "Enter property owners' details here",
      //   link: "/owners",
      //   bgColor: "#cabdad", // BlanchedAlmond
      //   textColor: "#452209", // SaddleBrown
      // },
      {
        title: "Transfer",
        description: "Transfer properties or search property transfers here",
        link: "/proptrans",
        bgColor: "#ffe4e1", 
        textColor: "#541a8c", 
      },
      
      {
        title: "Add PIV",
        description: "Add PIV for assessment tax payments here",
        link: "/aspiv",
        bgColor: "#e8dfdf", 
        textColor: "#8d6161", 
      },
      {
        title: "Search Properties",
        description: "Search Property details by Property ID or by Ward & Street",
        link: "/aspropsearch",
        bgColor: "#eca3a3", 
        textColor: "#faeaea",
      },
      {
        title: "Payment Of Properties",
        description: "View payment of Properties",
        link: "/assepaidprop",
        // link:"/underdevelopment",
        bgColor: "#bbe7f2", 
        textColor: "#2986cc",
      },
    ],
      }
  },
  computed: {
      ...mapState(["user"])
  },
  created() {
      
  },


  methods: {
      ...mapMutations(["setUser"]),

      
       
  }

}
</script>

<style scoped>
.button-grid {
display: grid;
grid-template-columns: repeat(3, 1fr); /* 3 columns */
gap: 15px; /* Space between buttons */
margin: 20px;
}

.upbtn {
background-color: rgb(80, 77, 77);;
color: white;
text-align: center;
padding: 15px;
border-radius: 8px;
text-decoration: none;
font-size: 18px;
font-weight: bold;
transition: background-color 0.3s ease, transform 0.2s ease;
}

.upbtn:hover {
background-color: rgb(170, 173, 170);
transform: scale(1.05);
}
.admin-container{
   min-height: 90vh;
}
/* home.vue's all styles below */
.home-main,
.home-about,
.home-banner,
.home-category {
  padding: 2rem 9%;
  /* max-height: 250px; */
}

.home-main {
  display: flex;
  align-items: center;
  flex-wrap: wrap-reverse;
  gap: 2rem;
  position: relative;
  overflow: hidden;
  
}

.home-main .content {
  flex: 1 1 41rem;
  /* padding-bottom: 2.5rem; */
  /* padding-top: 2.5rem; */
 
}

.home-main .content span {
  font-size: 2rem;
  color: #022e2a;
}

.home-main .content h3 {
  font-size: 3rem;
  color: #130f40;
  padding-top: .5rem;
}

.home-main .content p {
  font-size: 1.6rem;
  color: #666;
  line-height: 2;
  padding: 1rem 0;
}

.home-main .image {
  flex: 1 1 41rem;
  margin: 2rem 0;
  pointer-events: none;
}

.home-main .image .home-img {
  width: 100%;
  margin-top: 5rem;
}

.home-main .home-parallax-img {
  position: absolute;
  top: -15rem;
  right: -15rem;
  width: 80vw;

}
.card-grid {
display: grid;
grid-template-columns: repeat(3, 1fr); /* 3 cards per row */
gap: 20px;
padding: 20px;
padding-top: none;
}

.card {
background-color: #fff;
border-radius: 8px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
overflow: hidden;
transition: transform 0.2s, box-shadow 0.2s;
text-decoration: none;
color: inherit;
height: 140px;
}

.card:hover {
transform: scale(1.05);
box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.card-img {
width: 100%;
height: 150px;
object-fit: cover;
}

.card-content {
padding: 15px;
text-align: center;
}

.card-content h3 {
margin: 10px 0;
font-size: 2.0rem;
}

.card-content p {
color: #666;
}

@media (max-width: 768px) {
.card-grid {
  grid-template-columns: repeat(2, 1fr); /* 2 cards per row */
}
}

@media (max-width: 480px) {
.card-grid {
  grid-template-columns: 1fr; /* 1 card per row */
}
}

</style>