<template>
    <div class="menu-section">
       
<!-- start -->
<vue-basic-alert :duration="1000" :closeIn="20000" ref="alert" />
<div class="register-container">
       <div class="register-form-container">
           <form id="userForm" @submit="handleSubmit1" novalidate autocomplete="off">
               <div class="heading">
                   <h3>Invoice Details </h3>
               </div>
               <div class="container">
               <h4>Customer Details</h4>
                   
               </div>
               
               <div class="container">
                <div class="row">
                    <div class="col-sm">
                        <label for="cNic">NIC Number: 
                   </label>
                  <input type="text" name="cNic" placeholder="Customer NIC" id="cNic" class="form-control"
                   @input ="showCustomer" v-model="cusObj.nic"/>
                       <p class="error-mess" v-if="errObj.nicErr.length > 0">{{ errObj.nicErr[0] }}</p>
                   
                    </div>
                </div>
                   <div class="row">
                       <div class="col-sm">
                           <label for="cName">Name:
                                       </label>
                                       <input type="text" name="cName" placeholder="Customer Name" id="cName" class="form-control"
                                           v-model="cusObj.name" />
                                           <p class="error-mess" v-if="errObj.nameErr.length > 0">{{ errObj.nameErr[0] }}</p>
                       </div>
                       <div class="col-sm">
                           <label for="cPhone">Contact:
                                       </label>
                                   <input type="text" name="cPhone" placeholder="Contact number" id="cPhone" class="form-control" 
                                       v-model="cusObj.phone" />
                                       <p class="error-mess" v-if="errObj.phoneErr.length > 0">{{ errObj.phoneErr[0] }}</p>
                       </div>
                       <div class="col-sm">
                           <label for="cAddress">Address:
                                       </label>
                                   <input type="text" name="cAddress" placeholder="Customer Address" id="cAddress" class="form-control" 
                                       v-model="cusObj.address" />
                                       <p class="error-mess" v-if="errObj.addErr.length > 0">{{ errObj.addErr[0] }}</p>
                       </div>
                   </div>
                   <div class="row">
                   <div class="col-sm">
                    <input type="submit" value="Add" class="btn" id ="Add" />
                    </div>
                </div>
               </div>
                           <!-- <div class="form-group">
                               <input type="submit" value="Add" class="btn" id ="Add" />
                           </div> -->

           </form>
       </div>

<!-- end -->
</div>

       
<!-- start -->
<div class="shroff-container">
       <div class="shroff-form-container">
           <form id="addIncomeForm" @submit="handleSubmit2" novalidate autocomplete="off">
            <div class="heading">
                   <h3>Payment Details </h3>
               </div>
               <div class="row">
               <div class="form-group col-sm-6">
                   <label for="iHead">Income Head:
                   </label>
                 <select v-model="incomeObj.headid" id="iHead" class="form-control" @change ="checkShop()" >
                       <option value="" selected disabled>
                                Choose
                       </option>
                       <option v-for="ratehead in sabhaIncomeHead" :key="ratehead.emp_sb_rates"  
                           v-bind:value =[ratehead.emp_sb_rates,ratehead.subjecttype]  >
                             {{ ratehead.emp_sb_rates  }} - {{ratehead.sb_rate_head_name}}
                       </option>
                   </select>

                   <p class="error-mess" v-if="errIncomeObj.iheadErr.length > 0">{{ errIncomeObj.iheadErr[0] }}</p> 
               </div>
            </div>
                <!-- Shop Detail -->
               <div class="form-group" id="shopdetail" style="display: none;">
                
                  <table class="ttable" >
               <thead>
                   <tr>   
                       <th>Year Month</th>
                       <th>Shop Number</th>
                       <th>Shop Place</th>
                       
                       <th>Previous Year <br>Arrears</th>
                       <th>Monthly rent</th>
                       <th>Current Year <br>Arrears</th>
                       <!-- <th>Fine</th> -->
                       <th>Select</th>
                   </tr>
               </thead>
               <tbody>
                   <tr v-for=" s in shopData" :key="s.shop_details_id ">
                        <td>{{ currentDate.getFullYear() }} - {{ getMonthName(currentDate.getMonth()+1 ) }}</td>
                       <td>{{ s.shop_number  }}</td>
                       <td>{{ s.place }}</td>
                       <!-- <td>{{ getPaymentOfMonth(s.pay_month ,s.monthly_shop_rent)  }}</td> -->
                       <td>{{ DiffOfMonthstwoYear(s.pay_month ,s.monthly_shop_rent,s.arrears,s.pay_year).previousYearArrears }}</td>

                        <td>{{ s.monthly_shop_rent }}</td>
                        <td>{{ DiffOfMonthstwoYear(s.pay_month ,s.monthly_shop_rent,s.arrears,s.pay_year).currentYearArrears }}</td>
                       <!-- <td>{{ s.fine }}</td> -->
                       <!-- <td><input type="checkbox" id="shopcheck"  v-model="incomeObj.selectedShops" :true-value="s.shop_details_id" @Change="selectShops()"></td> -->
                       <td><input type="checkbox" id="shopcheck"  v-model="selectedShops[s.shop_details_id]" @Change="selectShops(s.shop_details_id)"></td>
                      
                   </tr>
               </tbody>
           </table>
          <!-- <p>If monthly Rent is Zero this month payment is done.</p>  -->
           <p> මුදල් ගෙවීම සඳහා අවශ්‍යය කඩ කාමර අංකය තේරීම අත්‍යාවශ්‍ය වේ.</p>
           <!-- pay month -->
            <!-- <p style="color: blue;font-size: small;">මුදල් ගෙවන අවසාන මාසය තෝරන්න. ඉදිරි මාස කිහිපයක් සඳහා ගෙවන්නේ නම් එම අවසාන මාසයද/ පෙර මාස කිහිපයක් සඳහා ගෙවන්නේ නම් එම අවසාන මාසයද තෝරන්න ‍‍| Please select month for payment. If you are paying ahead for several months, select ending month. If you are paying for previous months, select ending month</p> -->
            <!-- <p style="font-size: 1.3rem; color: black;"> Ex: paying for March,April and May; select May</p> -->
            <!-- <div class="row">
            <div class="form-group col-sm-4" >
           <label for="monthSelect">Payment month:</label>
           <input type="month" name="imon"  id="imon" class="form-control"
                        v-model="incomeObj.emonthyr" />
                   
                    <p class="error-mess" v-if="errIncomeObj.monyrErr.length > 0">{{ errIncomeObj.monyrErr[0] }}</p>
               </div>
               </div> -->
               </div>
               <!-- <div id="grandtotalcur" style="display: none; font-size:16px;">දඩ මුදල(රුපියල්) : {{fine.toFixed(2) }}</div> -->
               
               <div class="form-group" >
                   <label for="iDes">Description: {{ incomeObj.shopData }}
                   </label>
                   <input type="textarea" name="iDes"  id="iDes" class="form-control"
                    v-model="incomeObj.des" />
                    <p class="error-mess" v-if="errIncomeObj.iDesErr.length > 0">{{ errIncomeObj.iDesErr[0] }}</p>
               </div>

               <!-- ----------------------------------Previous Year----------------------------------------------- -->
               <br>
               <div class="row"> <div class="col-sm-12" id="ifinesegh1" style="display: none;"><h4>Previous Year<hr></h4></div> </div>
              
               <div class="row">
                
               <div id="ifinesegar" style="display: none;" class="col-sm-4" >
                    <label for="ifine"> Arrears Amount
                    </label>
                    <input type="number" name="iarr" id="iarr" class="form-control" 
                    v-model="incomeObj.preArramount"    />
                </div>
                <div id="ifinesegvote" style="display: none;" class="col-sm-4" >
                    <label for="iHead">Arrears Vote:
                   </label>
                 <select v-model="incomeObj.preArrvote" id="iHead" class="form-control"  >
                       <option value="" selected disabled>
                                Choose
                       </option>
                        <option v-for="ratehead in filteredEmpShopVotes" :key="ratehead.emp_sb_rates"
                                v-bind:value="ratehead.emp_sb_rates">
                        {{ ratehead.emp_sb_rates }} - {{ ratehead.sb_rate_head_name }}
                        </option>
                     
                   </select>
                   <p class="error-mess" v-if="errIncomeObj.preArrvoteErr.length > 0">{{ errIncomeObj.preArrvoteErr[0] }}</p>
                    
                </div>
                </div>

                <div class="row">
                
                <div id="ifinesegpfine" style="display: none;" class="col-sm-4" >
                     <label for="ifine"> Fine Amount
                     </label>
                     <input type="number" name="iarr" id="iarr" class="form-control" 
                     v-model="incomeObj.preFine"    />
                 </div>
                 <div id="ifinesegpfinevote" style="display: none;" class="col-sm-4" >
                     <label for="iHead">Fine Vote:
                    </label>
                  <select v-model="incomeObj.preFinevote" id="iHead" class="form-control"  >
                        <option value="" selected disabled>
                                 Choose
                        </option>
                         <option v-for="ratehead in filteredEmpShopVotes" :key="ratehead.emp_sb_rates"
                                 v-bind:value="ratehead.emp_sb_rates">
                         {{ ratehead.emp_sb_rates }} - {{ ratehead.sb_rate_head_name }}
                         </option>
                      
                    </select>
                    <p class="error-mess" v-if="errIncomeObj.preFinevoteErr.length > 0">{{ errIncomeObj.preFinevoteErr[0] }}</p>
                     
                 </div>
                 </div>
                 <div id="grandtotal" style="display: none; font-size:16px;">පෙර වර් ෂයේ ගෙවීමට ඇති මුළු මුදල(රුපියල්) : {{ PretotalAmountRent.toFixed(2) }}</div>
                
<!----------------------------------- Current year ---------------------------------------------------------------->
                <br>
               <div class="row"> <div class="col-sm-12" id="ifinesegh2" style="display: none;"><h4>Current Year<hr></h4></div> </div>
              
               <div class="row">
               <div id="ifinesegcyArreas" style="display: none;" class="col-sm-4" >
                    <label for="ifine">Arrears Amount
                    </label>
                    <input type="number" name="ifine" id="ifine" class="form-control" 
                    v-model="incomeObj.cyArreas"    />
                </div>

                <div id="ifinesegfcyArreasvote" style="display: none;" class="col-sm-4" >
                     <label for="iHead">Arrears Vote:
                    </label>
                  <select v-model="incomeObj.cyArreasvote" id="iHead" class="form-control"  >
                        <option value="" selected disabled>
                                 Choose
                        </option>
                         <option v-for="ratehead in filteredEmpShopVotes" :key="ratehead.emp_sb_rates"
                                 v-bind:value="ratehead.emp_sb_rates">
                         {{ ratehead.emp_sb_rates }} - {{ ratehead.sb_rate_head_name }}
                         </option>
                      
                    </select>
                    <p class="error-mess" v-if="errIncomeObj.cyArreasErr.length > 0">{{ errIncomeObj.cyArreasErr[0] }}</p>
                     
                 </div>
                </div>

                <div class="row">
               <div id="ifineseg" style="display: none;" class="col-sm-4" >
                    <label for="ifine">Fine Amount
                    </label>
                    <input type="number" name="ifine" id="ifine" class="form-control" 
                    v-model="incomeObj.fineamount"   readonly />
                </div>

                <div id="ifinesegfvote" style="display: none;" class="col-sm-4" >
                     <label for="iHead">Fine Vote:
                    </label>
                  <select v-model="incomeObj.finevote" id="iHead" class="form-control"  >
                        <option value="" selected disabled>
                                 Choose
                        </option>
                         <option v-for="ratehead in filteredEmpShopVotes" :key="ratehead.emp_sb_rates"
                                 v-bind:value="ratehead.emp_sb_rates">
                         {{ ratehead.emp_sb_rates }} - {{ ratehead.sb_rate_head_name }}
                         </option>
                      
                    </select>
                    <p class="error-mess" v-if="errIncomeObj.fineVoteErr.length > 0">{{ errIncomeObj.fineVoteErr[0] }}</p>
                     
                 </div>
                </div>

                <div class="row">
               <div id="ifinesegrentmonthly" style="display: none;" class="col-sm-4" >
                    <label for="ifine">Monthly Rent:
                    </label>
                    <input type="number" name="ifine" id="ifine" class="form-control" 
                    v-model="incomeObj.rentmonthly"    />
                </div>

                <div id="ifinesegfrentvote" style="display: none;" class="col-sm-4" >
                     <label for="iHead">Rent Vote:
                    </label>
                  <select v-model="incomeObj.rentvote" id="iHead" class="form-control"  >
                        <option value="" selected disabled>
                                 Choose
                        </option>
                         <option v-for="ratehead in filteredEmpShopVotes" :key="ratehead.emp_sb_rates"
                                 v-bind:value="ratehead.emp_sb_rates">
                         {{ ratehead.emp_sb_rates }} - {{ ratehead.sb_rate_head_name }}
                         </option>
                      
                    </select>
                    <p class="error-mess" v-if="errIncomeObj.arVoteamountErr.length > 0">{{ errIncomeObj.arVoteamountErr[0] }}</p>
                     
                 </div>
                </div>
                <div id="grandtotalcur" style="display: none; font-size:16px;">
                    මෙම වර් ෂයේ මේ දක්වා ගෙවීමට ඇති මුළු මුදල(රුපියල්) : {{ totalAmountRent.toFixed(2) }}<br>
                    ගෙවීමට ඇති මුළු මුදල(රුපියල්) : {{ fullTotalRent.toFixed(2) }}
                </div>
               <div class="row" >
                   <div class="col-sm-2" style="display: block;" id="pivamount">
                   <label for="iAmount">Amount:
                   </label>
                   <input type="text" name="iAmount"  id="iAmount" class="form-control"  
                     v-model="incomeObj.amount"  @input="changeval"/>
                    <p class="error-mess" v-if="errIncomeObj.amountErr.length > 0">{{ errIncomeObj.amountErr[0] }}</p>
               </div>
               <div class="col-sm-2" style="display: block;" id="pivstamp">
                   <label for="iStamp">Stamp Fee:
                   </label>
                   <input type="text" name="iStamp"  id="iStamp" class="form-control" 
                       v-model="incomeObj.stamp" />
                   <p class="error-mess" v-if="errIncomeObj.stampErr.length > 0">{{ errIncomeObj.stampErr[0] }}</p>
               </div>
               <div class="col-sm-2" style="display: block;" id="pivdis">
                   <label for="iDisc">Discounts:
                   </label>
                   <input type="text" name="iDisc"  id="iDisc" class="form-control" 
                       v-model="incomeObj.discounts" />
                   <p class="error-mess" v-if="errIncomeObj.discErr.length > 0">{{ errIncomeObj.discErr[0] }}</p>
               </div>
               <div class="col-sm-2" style="display: block;" id="pivvat">
                    <label for="iDisc">VAT: (%)</label>
                    <input type="text" name="iVat" id="iVat" class="form-control" 
                    v-model="incomeObj.vat"    />
                   <br>
                </div>
                
               <div class="Addbtn col-sm-4">
                   <label for="iRates">
                   </label>
                   <input type="button" value="Add" id="Addpay" class=" btn" @click="addToTable" /> 
               </div>
           </div>
           <!-- <div id="arreas" style="display: none;">ගෙවීමට ඇති හිඟ මුදල (රුපියල්) : {{ total_arresrs.toFixed(2) }}</div> -->
          
           <br>
           <div class="row">
               <table class="ttable" >
               <thead>
                   <tr>   
                       <th>NIC/Ref: No</th>
                       <th>Income head</th>
                       <th>Description</th>
                       <th>Amount</th>
                       <th>Stamp fee</th>
                       <th>Discounts</th>
                       <th>Vat(%)</th>
                       <th>Remove</th>
                   </tr>
               </thead>
               <tbody>
                   <tr v-for=" r in allreceipt" :key="r.id">
                        <td>{{ r.cus_nic }}</td>
                       <td>{{ r.sb_rate_head }}</td>
                       <td style="width: 30%;">{{ r.description }}</td>
                       <td>{{ $formatNumber(r.amount) }}</td>
                       <td>{{ $formatNumber(r.stamp) }}</td>
                       <td>{{ $formatNumber(r.discount) }} </td>
                       <td>{{ $formatNumber(r.vat) }}</td>
                       <td><input type="button" @click="deleteReceipt(r.id)" value="X" class=" bbtn"></td>
                     
                   </tr>
               </tbody>
           </table>
           </div>
           
           </form>
       </div>
</div>
<!-- end -->
</div>
</template>

<script>
import axios from 'axios';
import VueBasicAlert from 'vue-basic-alert';
export default {
   name: "SubjectDashboard",

   data() {
       return {
           cusObj : { nic: "", name: "", phone:"0", address:"" },
           errObj : {nicErr:[], nameErr:[], phoneErr:[], addErr:[]},
           matchCus : undefined,
           theUser :[],
           nic:"",
           sabha: "",
           userLevel:"",
           incomeObj : { headid:"", des:"", amount:"0.00", stamp:"0.00", discounts:"0.00", vat:"0", selectedShops:"", 
           prepairedby:"",efine:"0",emonthyr:"0",fineamount:"0",finevote:"",preArramount:"0",preArrvote:"",preFine:"0.00",preFinevote:"",
            cyArreas:"0.00",cyArreasvote:"",rentmonthly:"0.00",rentvote:""},

           errIncomeObj:{iheadErr:[], iDesErr:[], amountErr:[], stampErr:[], discErr:[],monyrErr:[],arVoteamountErr:[],
            preArrvoteErr:[],preFinevoteErr:[],cyArreasErr:[],fineVoteErr:[]
           },
           sabhaIncomeHead : undefined,
           ratehead : '',
           tablear:[],
           allreceipt:[],
           arrayy: undefined,
            shopData:[],
            shopDetails:[],
            // date : new Date(),
            currentDate: new Date(),
            total_arresrs:0,
            fine :0,
            totalAmountRent:0,
            surname:'',
            Nic12:0,
            selectedShops:[],
            shopList:[],
            sabhaCode:'',
            shouldDisable: false,
            shopdid:0,
            checkval:0,totalAmountShop:0,
            invdescription:'',
            invshopdid:0,
            userName:"",
            selectedNumber:'',
            selectyr:"",
            selectmon:"",serverDate:'',
            finedate:0,fineRate:0,paymentmonth:0,
            paymentyear:0,PretotalAmountRent:0,prefineamount:0,
            fullTotalRent:0,curTotalArrears:0
           
       };
   },
   created() {
       this.getDataFromSessionStorage()
      //  this.showCustomer()
     
   },
   computed : {
            isGreaterThan10th() {
                return this.currentDate.getDate() > this.finedate;
                },
                filteredEmpShopVotes() {
    return this.sabhaIncomeHead
      ? this.sabhaIncomeHead.filter(ratehead => ratehead.subjecttype === 'shoprent')
      : []; // Return an empty array if undefined
  }
        },

   methods: {
    formattedDate() {
            // const day = this.date.getDate().toString().padStart(2, "0");
            // const month = (this.date.getMonth() + 1)
            //   .toString()
            //   .padStart(2, "0");
            // const year = this.date.getFullYear().toString();
            // // this.defaultdate =`${year}-${month}-${day}`
            // return `${year}-${month}-${day}`;
            let date = new Date(this.serverDate)
            const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
          },
       async getDataFromSessionStorage(){
           const data = JSON.parse(sessionStorage.getItem('userData'))
           if(data){
                           this.nic = data.nic 
                           this.sabha = data.sabha
                           this.userLevel =data.userLevel
                           this.userName = data.userName
                       }
           this.sabhaCode = this.sabha
           let Sabhadata = await axios.get('/subheads/'+ this.nic +"/"+ this.sabhaCode)
           this.sabhaIncomeHead = Sabhadata.data
           this.incomeObj.prepairedby =  this.userName
           this.allreceipt =  (await axios.get('/ownpiv/'+ this.nic +"/"+ this.sabhaCode)).data
         

           const theSabha= (await axios.get('/pra_sabha/' +this.sabha)).data;
           this.finedate=theSabha.fine_date;
           this.fineRate=theSabha.fine_rate;

                try {
                    const response = await axios.get('/getserverdate'); // Ensure correct API route
                    this.serverDate = response.data.serverDate; // Ensure correct response key
                    console.log(response.data);
                  //   this.formatcurrentDate=this.getCurrentDate(this.serverDate);
                  this.currentDate=new Date(this.serverDate);
                
                } catch (error) {
                    console.error("Error fetching server date:", error);
                }
                this.paymentmonth=parseInt(this.currentDate.getMonth())+1;
                this.paymentyear = this.currentDate.getFullYear();
       },
  
                getMonthName: function (monthNum) {
                    const monthNumber = parseInt(monthNum);
                if (monthNumber < 1 || monthNumber > 12) {
                    console.error('Invalid month number. Please provide a number between 1 and 12.');
                    return '';
                }

                const date = new Date(this.serverDate);
                date.setMonth(monthNumber-1); // Subtract 1 to align with zero-based index

                console.log(date.toLocaleString('en-US', { month: 'long' })); // Correct logging
                return date.toLocaleString('en-US', { month: 'long' });
            },

          getPaymentOfMonth:function( paymonth1,rent,pyear) {
            
            let Mpayment =rent;

                  if (paymonth1== parseInt(this.currentDate.getMonth())+1 && pyear==this.currentDate.getFullYear()) {
                  Mpayment=0
                  }
                  else{
                    Mpayment=rent
                  }

                  return Mpayment;
         },
         addZero(num) {

            num = num.toString();
                return '19' + num.slice(0, 5) + '0' + num.slice(5, -1);
            },
            addZeroPadding: function() {
            // this.len= this.loginObj.nic.charAt(9).toUpperCase()
            // Check if the user input is a valid number
            // if (!isNaN(parseInt(this.cusObj.nic)) && this.cusObj.nic !== null && this.cusObj.nic.charAt(9).toUpperCase() === 'V') {
if (!isNaN(parseInt(this.cusObj.nic)) && this.cusObj.nic !== null && (this.cusObj.nic.charAt(9).toUpperCase() === 'V' || this.cusObj.nic.charAt(9).toUpperCase() === 'X')) {
                
                this.paddedNum = this.addZero(this.cusObj.nic);

            }else{
                this.paddedNum=parseInt(this.cusObj.nic);
            }
            return this.paddedNum
            },

async showCustomer() {
    let letter_first= (this.cusObj.nic).charAt(0)
            if(letter_first=='R'){
                this.Nic12=this.cusObj.nic
            }else{
            // this.cus_nic = JSON.parse(sessionStorage.getItem("cus_nic"))
            this.Nic12 = this.addZeroPadding(this.cusObj.nic); 
            }
         this.Nic12 = this.addZeroPadding();
       this.theUser= (await axios.get('/customers/'+this.Nic12+"/"+this.sabhaCode)).data;
       if(this.theUser.length>0)
        {  
        
           this.cusObj.name = this.theUser[0].cus_name;
           this.cusObj.phone = this.theUser[0].cus_contact;
           this.cusObj.address = this.theUser[0].cus_address;
           document.getElementById("Add").style.visibility ="hidden" ;
         //   this.cusObj.phone = this.theUser[0].cus_contact;
         //   this.cusObj.address = this.theUser[0].cus_address;
         //   document.getElementById("Add").style.visibility ="hidden" ;
        }
        else{
           document.getElementById("Add").style.visibility ="visible" ;
           this.cusObj.name = " ";
           this.cusObj.phone = " ";
           this.cusObj.address = " "; 
        }
      //  this.theUser= (await axios.get('/customers/194535002627' )).data;
    //    this.allreceipt = (await axios.get('/pivbyidrow/'+this.Nic12+'/'+this.nic)).data
    this.allreceipt =  (await axios.get('/ownpiv/'+ this.nic +"/"+ this.sabhaCode)).data
       //shopdata
      
         //   this.surname= this.theUser.Cus_name+"/"+this.theUser.cus_name;
         this.shopData = (await axios.get('/shopdata/' +this.Nic12 +"/"+ this.sabha )).data
       
  },
  async changeval() {
    let preremainamount = 0, remainamount = 0;
    let curremainamount = 0, paymonth = 0, payyear = 0;

    let shopData1 = (await axios.get(`/shopdata/${this.Nic12}/${this.sabha}`)).data;
    // let rentmon = parseFloat(shopData1[0].monthly_shop_rent);

    // Check if selected head is 'shoprent'
    if (this.incomeObj.headid[1] === 'shoprent') {

        preremainamount = parseFloat(this.incomeObj.amount) - parseFloat(this.PretotalAmountRent);
     this.total_arresrs = this.DiffOfMonthstwoYear(
                shopData1[0].pay_month,
                shopData1[0].monthly_shop_rent,
                shopData1[0].arrears,
                shopData1[0].pay_year
            );
        // Handle Remaining Amount After Fine Deduction
        if (preremainamount > 0 && this.PretotalAmountRent>=0) {
            // Calculate Arrears Amount
            let paym=0;
            // let monthlyRent = parseFloat(shopData1[0].monthly_shop_rent);
                let paycurmon = parseInt(shopData1[0].pay_month);
                let pyacurYear=parseInt(shopData1[0].pay_year);

            this.incomeObj.preArramount = parseFloat(this.total_arresrs.previousYearArrears).toFixed(2);
            this.incomeObj.cyArreas = parseFloat(this.total_arresrs.currentYearArrears).toFixed(2);
            this.incomeObj.fineamount = parseFloat(this.fine).toFixed(2);
            this.incomeObj.rentmonthly = parseFloat(shopData1[0].monthly_shop_rent).toFixed(2);
            curremainamount = parseFloat(this.totalAmountRent)-preremainamount;

            if (curremainamount >0) {
                // this.$refs.alert.showAlert('error', 'curremainamount! ' + `${curremainamount}`);
                
                let curarrsub=parseFloat(preremainamount)-parseFloat(this.incomeObj.cyArreas);

                let curfinesub=0; let rentsub=0;
                            if (curarrsub >0 ) {
                                curfinesub=parseFloat(curarrsub)-parseFloat(this.incomeObj.fineamount);
                                // this.$refs.alert.showAlert('error', 'curremainamount! ' + `${curremainamount}`);

                                if(curfinesub>0){
                                         rentsub=parseFloat(curfinesub)-parseFloat(this.incomeObj.rentmonthly);
                                         if(rentsub>=0){
                                        this.$refs.alert.showAlert('error', 'curfinesub! ' + `${rentsub}`);

                                                const monthlyRent = parseFloat(shopData1[0].monthly_shop_rent);
                                                remainamount = Math.floor(rentsub / monthlyRent); // Full rent months covered
                                                // let mod = rentsub % monthlyRent; // Excess amount remaining after monthly payments

                                                // Calculate Current Payment Month and Year
                                                let currentMonth = this.currentDate.getMonth() + 1; // JS months are 0-based
                                                let currentYear = this.currentDate.getFullYear();

                                                if (remainamount > 0) {
                                                    paymonth = currentMonth + remainamount;
                                                    while (paymonth > 12) {
                                                        paymonth -= 12;
                                                        currentYear += 1; // Adjust year if month exceeds 12
                                                    }
                                                } else {
                                                    paymonth = currentMonth; // No full month payment, stay in the current month
                                                }

                                                // Update Payment Year and Month
                                                payyear = currentYear;
                                                this.paymentmonth = parseInt(paymonth);
                                                this.paymentyear = parseInt(payyear);

                                                // Handle Remainder (Excess after full month payments)
                                                this.curTotalArrears =parseFloat(monthlyRent-rentsub).toFixed(2);
                                            
                                    }else{
                                    this.$refs.alert.showAlert('error', 'rentsub! ' + `${rentsub}`);

                                        if(rentsub==parseFloat(this.incomeObj.rentmonthly)){
                                            this.incomeObj.rentmonthly=parseFloat(shopData1[0].monthly_shop_rent).toFixed(2);
                                            let currentMonth = this.currentDate.getMonth() + 1; 
                                            let currentYear = this.currentDate.getFullYear();
                                            this.paymentmonth = parseInt(currentMonth);
                                            this.paymentyear = parseInt(currentYear);
                                            this.curTotalArrears=0;
                                        }else{
                                            this.incomeObj.rentmonthly=(parseFloat(shopData1[0].monthly_shop_rent)+parseFloat(rentsub)).toFixed(2);
                                            this.curTotalArrears=rentsub;
                                            let currentMonth = this.currentDate.getMonth() + 1; 
                                            let currentYear = this.currentDate.getFullYear();
                                            this.paymentmonth = parseInt(currentMonth);
                                            this.paymentyear = parseInt(currentYear);
                                            let payarram=parseFloat(shopData1[0].monthly_shop_rent)-(parseFloat(this.incomeObj.rentmonthly));

                                            this.curTotalArrears=payarram.toFixed(2);
                                        }
                                    }///end rentsub
                                }   
                                else{

                                    if(curfinesub==parseFloat(this.incomeObj.fineamount)){
                                    this.incomeObj.fineamount=parseFloat(this.fine).toFixed(2);

                                    let currentMonth = this.currentDate.getMonth() + 1; 
                                            let currentYear = this.currentDate.getFullYear();
                                    const monthlyRent = parseFloat(shopData1[0].monthly_shop_rent);

                                    let CURmontharr=parseFloat(shopData1[0].monthly_shop_rent).toFixed(2);
                                            let PRYroundmonth222cur=Math.floor(CURmontharr / monthlyRent);

                                                if (PRYroundmonth222cur > 0) { // Adjust the condition to use PRYroundmonth222cur
                                                    paym = currentMonth - PRYroundmonth222cur;
                                                    
                                                    // Adjust month and year if needed (to handle payments rolling into the previous year)
                                                    while (paym <= 0) { // Adjust while condition to handle month overflow correctly
                                                        paym += 12;
                                                        currentYear -= 1; // Move to the previous year if month drops below 1
                                                    }
                                                } else {
                                                    paym = currentMonth; // No full month payment, stay in the current month
                                                }
                                            let payyearPr = currentYear;
                                            this.paymentmonth = parseInt(paym);
                                            this.paymentyear = parseInt(payyearPr);
                                            this.curTotalArrears=0;

                                    this.curTotalArrears=0;


                                    
                                    }else{
                                    this.incomeObj.fineamount=(parseFloat(this.fine)+parseFloat(curfinesub)).toFixed(2);
                                    const monthlyRent = parseFloat(shopData1[0].monthly_shop_rent);
                                    let currentMonth = this.currentDate.getMonth() + 1; 
                                    let currentYear = this.currentDate.getFullYear();
                                            let CURmontharr=(parseFloat(shopData1[0].monthly_shop_rent)-curfinesub).toFixed(2);
                                            let PRYroundmonth222cur=Math.floor(CURmontharr / monthlyRent);

                                                if (PRYroundmonth222cur > 0) { // Adjust the condition to use PRYroundmonth222cur
                                                    paym = currentMonth - PRYroundmonth222cur;
                                                    
                                                    // Adjust month and year if needed (to handle payments rolling into the previous year)
                                                    while (paym <= 0) { // Adjust while condition to handle month overflow correctly
                                                        paym += 12;
                                                        currentYear -= 1; // Move to the previous year if month drops below 1
                                                    }
                                                } else {
                                                    paym = currentMonth; // No full month payment, stay in the current month
                                                }
                                            let payyearPr = currentYear;
                                            this.paymentmonth = parseInt(paym);
                                            this.paymentyear = parseInt(payyearPr);
                                            this.curTotalArrears=0;

                                    this.curTotalArrears=Math.abs(curfinesub).toFixed(2);
                                    }
                                    this.incomeObj.rentmonthly = 0;
                                    
                                }//end finesub
                    

                            }
                            else{

                                if(preremainamount==parseFloat(this.incomeObj.cyArreas)){
                                    const monthlyRent = parseFloat(shopData1[0].monthly_shop_rent);

                                    this.incomeObj.cyArreas= parseFloat(this.total_arresrs.currentYearArrears).toFixed(2);
                                    let PRYroundmonth222=Math.floor(this.incomeObj.cyArreas / monthlyRent);

                                            if (PRYroundmonth222 > 0) {
                                                paym = paycurmon + PRYroundmonth222;
                                                    while (paym > 12) {
                                                        paym -= 12;
                                                        pyacurYear += 1; // Adjust year if month exceeds 12
                                                    }
                                                } else {
                                                    paym = paycurmon; // No full month payment, stay in the current month
                                                }

                                                let payyearPr = pyacurYear;
                                                this.paymentmonth = parseInt(paym);
                                                this.paymentyear = parseInt(payyearPr);
                                                this.curTotalArrears=0;
                                }else{
                                    this.incomeObj.cyArreas=(parseFloat(this.total_arresrs.currentYearArrears)+parseFloat(curarrsub)).toFixed(2);
                                    const monthlyRent = parseFloat(shopData1[0].monthly_shop_rent);
                                   
                                    let PRYroundmonth2221=Math.floor(this.incomeObj.cyArreas / monthlyRent);

                                            if (PRYroundmonth2221 > 0) {
                                                paym = paycurmon + PRYroundmonth2221;
                                                    while (paym > 12) {
                                                        paym -= 12;
                                                        pyacurYear += 1; // Adjust year if month exceeds 12
                                                    }
                                                } else {
                                                    paym = paycurmon; // No full month payment, stay in the current month
                                                }

                                                let payyearPr = pyacurYear;
                                                this.paymentmonth = parseInt(paym);
                                                this.paymentyear = parseInt(payyearPr);
                                                let payarram=parseFloat(this.total_arresrs.currentYearArrears)-(parseFloat(this.incomeObj.cyArreas));
                                            this.curTotalArrears=payarram.toFixed(2);

                                }
                                this.incomeObj.fineamount = 0;
                                this.incomeObj.rentmonthly = 0;
                            }//end curarrsub

                    }else{
                        let mod =0;
                                    const monthlyRent = parseFloat(shopData1[0].monthly_shop_rent);
                                    // let curR=curremainamount+
                                    let remainamount = Math.floor(curremainamount / monthlyRent); // Full rent months covered
                                     mod = curremainamount % monthlyRent; // Excess amount remaining after monthly payments

                                    // Calculate Current Payment Month and Year
                                    let currentMonth = this.currentDate.getMonth() + 1; // JS months are 0-based
                                    let currentYear = this.currentDate.getFullYear();

                                    if (remainamount > 0) {
                                        paymonth = currentMonth + remainamount;
                                        while (paymonth > 12) {
                                            paymonth -= 12;
                                            currentYear += 1; // Adjust year if month exceeds 12
                                        }
                                    } else {
                                        paymonth = currentMonth; // No full month payment, stay in the current month
                                    }

                                    // Update Payment Year and Month
                                    payyear = currentYear;
                                    this.paymentmonth = parseInt(paymonth);
                                    this.paymentyear = parseInt(payyear);

                                    // Handle Remainder (Excess after full month payments)
                                    this.curTotalArrears =parseFloat(mod).toFixed(2);

                                    this.incomeObj.cyArreas = parseFloat(this.total_arresrs.currentYearArrears).toFixed(2);
                                    this.incomeObj.fineamount = parseFloat(this.fine).toFixed(2);
                                    this.incomeObj.rentmonthly = parseFloat(shopData1[0].monthly_shop_rent).toFixed(2);
                                    // this.curTotalArrears=curremainamount.toFixed(2)
                     
                        }
        } else {
            
            // Handle cases where payment is less than or equal to Fine Amount
            let paym=0;
            let monthlyRent = parseFloat(shopData1[0].monthly_shop_rent);
                let paycurmon = parseInt(shopData1[0].pay_month);
                let pyacurYear=parseInt(shopData1[0].pay_year);
            let subfine = parseFloat(this.incomeObj.amount) - parseFloat(this.incomeObj.preFine);
            if (subfine == parseFloat(this.total_arresrs.previousYearArrears)) {
                this.incomeObj.preArramount =parseFloat(this.total_arresrs.previousYearArrears).toFixed(2);
                let PRYroundmonth223=Math.floor(this.incomeObj.preArramount / monthlyRent);

                                if (PRYroundmonth223 > 0) {
                                    paym = paycurmon + PRYroundmonth223;
                                        while (paym > 12) {
                                            paym -= 12;
                                            pyacurYear += 1; // Adjust year if month exceeds 12
                                        }
                                    } else {
                                        paym = paycurmon;
                                         // No full month payment, stay in the current month
                                    }

                                    let payyearPr = pyacurYear;
                                    this.paymentmonth = parseInt(paym);
                                    this.paymentyear = parseInt(payyearPr);

                                    this.curTotalArrears=0;

            } else if(subfine>0){
                this.incomeObj.preArramount =subfine.toFixed(2);
                
                let remainarrePRY = parseFloat(this.total_arresrs.previousYearArrears) - parseFloat(subfine);
                
                let PRYroundmonth=Math.floor(subfine / monthlyRent);

                                if (PRYroundmonth > 0) {
                                    paym = paycurmon + PRYroundmonth;
                                        while (paym > 12) {
                                            paym -= 12;
                                            pyacurYear += 1; // Adjust year if month exceeds 12
                                        }
                                    } else {
                                        paym = paycurmon; // No full month payment, stay in the current month
                                    }

                                    let payyearPr = pyacurYear;
                                    this.paymentmonth = parseInt(paym);
                                    this.paymentyear = parseInt(payyearPr);
                // this.$refs.alert.showAlert('error', 'subfine! ' + `${subfine}`);
              //  return; // Stop further if fine is not paid
              this.curTotalArrears=remainarrePRY;
            }else{

                this.incomeObj.preArramount="0.00"
            }
           
            // Reset current year arrears, fine, and monthly rent if fine is unpaid
            this.incomeObj.cyArreas = 0;
            this.incomeObj.fineamount = 0;
            this.incomeObj.rentmonthly = 0;
        }
    }
}
,

       async addToTable(e){
       
          this.checkForm2();
       
     if (!this.checkEmptyErr2()) {
         e.preventDefault();
     } else {
            
            
            // let paymonth=0;
            // let payyear=0;
            // this.changeval()
            if(this.incomeObj.headid[1]==='shoprent'){
                // paymentmonth =  this.selectmon;
                // paymentyear =this.selectyr;
                // this.paymentmonth=parseInt(this.currentDate.getMonth())+1;
                // this.paymentyear = parseInt(this.currentDate.getFullYear());
                let shopData1 = (await axios.get('/shopdata/' +this.Nic12 +"/"+ this.sabha )).data
 
            let rentmon=parseFloat(shopData1[0].monthly_shop_rent);
            let pmonth = shopData1[0].pay_month;
            let pYear=shopData1[0].pay_year;
            
            let checkMonth = this.getPaymentOfMonth(pmonth, rentmon,pYear);
                             if (checkMonth==0) {
                                 this.totalAmountShop =0
                             } else {
                                    this.totalAmountShop =parseFloat(rentmon)
                            }
                        //     else if (this.isGreaterThan10th && checkMonth==0){

                        //     //   this.fine = parseFloat(monthlyrent * 0.1);
                        //       this.totalAmountShop = parseFloat(this.fine)
                        //    }
                        // let cur_ar = this.DiffOfMonths(shopData1[0].pay_month,shopData1[0].monthly_shop_rent,shopData1[0].arrears,shopData1[0].pay_year)
                        
                        if (shopData1.length > 0 && rentmon > 0) {
                                const monthlyRent = parseFloat(shopData1[0].monthly_shop_rent);
                                const fineRatePercentage = parseFloat(this.fineRate) / 100;
                                
                                let numarr = this.total_arresrs.currentYearArrears > 0 ? (parseFloat(Math.floor(this.total_arresrs.currentYearArrears) / rentmon)) : 0;
                                
                                if (this.isGreaterThan10th && this.fineRate > 0 && checkMonth !== 0) {
                                    numarr += 1; // Extra fine if past the 10th
                                }
                                
                                if(this.total_arresrs.currentYearArrears >0){
                                    this.fine = numarr > 0 ? monthlyRent * fineRatePercentage * numarr : 0;

                                }else{
                                    this.fine = numarr > 0 ? this.totalAmountRent * fineRatePercentage * numarr : 0;
                                }

                                

                            } else {
                                this.fine = 0; // Handle cases where shopList is empty or rentmon is zero
                            }

                                 
                this.invdescription=this.incomeObj.des  
                this.invshopdid=this.shopdid
                let arrearsIncluded = false;  // Track if `shoptotalarrears` has been included

                const sendReceiptData = async (overrides) => {
                    const baseData = {
                        sabha_code: this.sabha,
                        cus_nic: this.Nic12,
                        cus_name: this.cusObj.name,
                        cus_contact: this.cusObj.phone,
                        cus_address: this.cusObj.address,
                        sb_rate_head: this.incomeObj.headid[0],
                        description: this.invdescription,
                        amount: parseFloat(this.incomeObj.amount).toFixed(2),
                        stamp: parseFloat(this.incomeObj.stamp).toFixed(2),
                        discount: parseFloat(this.incomeObj.discounts).toFixed(2),
                        paymonth: `${this.paymentmonth}-${this.paymentyear}`,
                        vat: parseFloat(this.incomeObj.vat).toFixed(2),
                        shopdid: this.invshopdid,
                        date: this.formattedDate(),
                        sub_nic: this.nic,
                    };

                    // Add `shoptotalarrears` only if it hasn't been sent yet, without including it in overrides
                    if (!arrearsIncluded) {
                        baseData.shoptotalarrears = parseFloat(this.curTotalArrears).toFixed(2);
                        arrearsIncluded = true;  // Mark as included after sending once
                    }

                    // Merge base data with other overrides and send POST request
                    await axios.post("/receipt/", { ...baseData, ...overrides });
                };

                // Send conditional rows, without adding `includeArrears` to the payload
                if (Number(this.incomeObj.rentmonthly) !== 0) {
                    await sendReceiptData({
                        description: `${this.invdescription} කඩ කාමර මුදල (Shop Rent)`,
                        amount: parseFloat(this.incomeObj.rentmonthly || 0).toFixed(2),
                        sb_rate_head: this.incomeObj.rentvote,
                        shopdid: `${this.invshopdid}-R`,
                    });
                }

                if (Number(this.incomeObj.cyArreas) !== 0) {
                    await sendReceiptData({
                        description: `${this.invdescription} මෙම වර්ෂයේ හිඟයෙන් මුදල (Arrears Amount)`,
                        amount: parseFloat(this.incomeObj.cyArreas || 0).toFixed(2),
                        sb_rate_head: this.incomeObj.cyArreasvote,
                        shopdid: `${this.invshopdid}-CA`,
                    });
                }

                if (Number(this.incomeObj.fineamount) !== 0) {
                    await sendReceiptData({
                        description: `${this.invdescription} දඩ මුදල (Fine Amount)`,
                        amount: parseFloat(this.incomeObj.fineamount || 0).toFixed(2),
                        sb_rate_head: this.incomeObj.finevote,
                        shopdid: `${this.invshopdid}-CF`,
                    });
                }

                if (Number(this.incomeObj.preArramount) !== 0) {
                    await sendReceiptData({
                        description: `${this.invdescription} පෙර වර්ෂයේ හිඟයෙන් මුදල (Arrears Amount)`,
                        amount: parseFloat(this.incomeObj.preArramount || 0).toFixed(2),
                        sb_rate_head: this.incomeObj.preArrvote,
                        shopdid: `${this.invshopdid}-PA`,
                    });
                }

                if (Number(this.incomeObj.preFine) !== 0) {
                    await sendReceiptData({
                        description: `${this.invdescription} පෙර වර්ෂයේ දඩ මුදල (Arrears Amount)`,
                        amount: parseFloat(this.incomeObj.preFine || 0).toFixed(2),
                        sb_rate_head: this.incomeObj.preFinevote,
                        shopdid: `${this.invshopdid}-PF`,
                    });
                }

                 
            }else{
                this.paymentmonth=parseInt(this.currentDate.getMonth())+1;
                this.paymentyear = parseInt(this.currentDate.getFullYear());
                this.invdescription=this.incomeObj.des
                this.invshopdid=0
                let data ={
                    sabha_code: this.sabha,
                    cus_nic : this.Nic12,
                   cus_name : this.cusObj.name,
                   cus_contact : this.cusObj.phone,
                   cus_address:this.cusObj.address,
                   sb_rate_head : this.incomeObj.headid[0],
                   description : this.invdescription,
                   amount : parseFloat(this.incomeObj.amount).toFixed(2),
                   stamp : parseFloat(this.incomeObj.stamp).toFixed(2),
                   discount : parseFloat(this.incomeObj.discounts).toFixed(2),
                   shoptotalarrears: parseFloat(this.total_arresrs).toFixed(2),
                   paymonth:`${this.paymentmonth}-${this.paymentyear}`,
                    // this.selectedNumber,
                //    paymonth:this.selectmon,
                   vat :parseFloat(this.incomeObj.vat).toFixed(2),
                   shopdid:this.invshopdid,
                   date : this.formattedDate() , //new Date() ,this.formattedDate(),
                   sub_nic : this.nic,
                  }

                   await axios.post("/receipt/", data);
            }
            // Function to send receipt data with dynamic overrides
        
                //    this.allreceipt = (await axios.get('/pivbyidrow/' +this.Nic12+'/'+this.nic)).data
                this.allreceipt =  (await axios.get('/ownpiv/'+ this.nic +"/"+ this.sabhaCode)).data
                  //  this.incomeObj.headid="",
                  
           // save to temporary_invoice_keep 
            let temporarydata ={
                sabha:this.sabha,
                 cus_nic : this.Nic12,
                   cus_name : this.cusObj.name,
                   cus_contact : this.cusObj.phone,
                    cus_address:this.cusObj.address,
                    sb_rate_head : this.incomeObj.headid[0],
                    description : this.invdescription,
                  amount : parseFloat(this.incomeObj.amount).toFixed(2),
                    stamp : parseFloat(this.incomeObj.stamp).toFixed(2),
                    discount : parseFloat(this.incomeObj.discounts).toFixed(2),
                    shoptotalarrears: parseFloat(this.total_arresrs).toFixed(2),
                    paymonth:`${this.paymentmonth}-${this.paymentyear}`,
                    // paymonth:this.selectmon,
                    vat :parseFloat(this.incomeObj.vat).toFixed(2),
                    shopdid:this.invshopdid,
                    date : this.formattedDate() , //new Date() ,this.formattedDate(),
                    sub_nic : this.nic,
                   }
                    await axios.post("/tempkeep/", temporarydata)
                    this.incomeObj.des="",
                   this.incomeObj.amount="0.00",
                   this.incomeObj.stamp="0.00",
                   this.incomeObj.discounts="0.00",
                   this.incomeObj.vat="0"
                   this.selectedShops=[];
                   this.fine=0;
                //    this.incomeObj.emonthyr=0;
                   this.incomeObj.fineamount=0;
                   this.incomeObj.headid="";
                   this.totalAmountRent=0;
                   document.getElementById('shopdetail').style.display ='none';
            // document.getElementById('arreas').style.display ='none';
            document.getElementById('grandtotalcur').style.display ='none';
        document.getElementById('grandtotal').style.display ='none';
     
            
         
         document.getElementById('ifineseg').style.display ="none";
         document.getElementById('ifinesegar').style.display ="none";
         document.getElementById('ifinesegpfinevote').style.display ="none";
         document.getElementById('ifinesegpfine').style.display ="none";
         document.getElementById('ifinesegh1').style.display ="none";
         document.getElementById('ifinesegh2').style.display ="none";
         document.getElementById('ifinesegvote').style.display ="none";
         document.getElementById('ifinesegfvote').style.display ="none";
         document.getElementById('ifinesegcyArreas').style.display ="none";
         document.getElementById('ifinesegfcyArreasvote').style.display ="none";
         document.getElementById('ifinesegrentmonthly').style.display ="none";
         document.getElementById('ifinesegfrentvote').style.display ="none";
        //  document.getElementById('pivamount').style.display ="block";
         document.getElementById('pivstamp').style.display ="block";
         document.getElementById('pivvat').style.display ="block";
         document.getElementById('pivdis').style.display ="block";
         this.shouldDisable = false;
         this.shopdid=0
         
         
         }
                   
       },


DiffOfMonths: function (pm, cr, current_arrears, payYear) {
   if (pm < 1 || pm > 12) {
      throw new Error('Month must be between 1 and 12');
   }

   let currentDate = new Date(this.serverDate);
   let currentMonth = currentDate.getMonth() + 1; // Convert to 1-12 format
   let currentYear = currentDate.getFullYear();

   // Calculate total months since year 0
   let currentYearMonth = currentYear * 12 + currentMonth;
   let pmInPayYear = payYear * 12 + pm;

   // Calculate difference
   let monthDifference = currentYearMonth - pmInPayYear;
   let monthDiff = Math.max(1, monthDifference); 

   // Calculate arrears
   let curArrears = (parseFloat(cr) * (monthDiff - 1)) + parseFloat(current_arrears);

   return curArrears.toFixed(2);
},
DiffOfMonthstwoYear: function (pm, cr, current_arrears, payYear) {
   if (pm < 1 || pm > 12) {
      throw new Error('Month must be between 1 and 12');
   }

   let currentDate = new Date(this.serverDate);
   let currentMonth = currentDate.getMonth() + 1; // Convert to 1-12 format
   let currentYear = currentDate.getFullYear();

   let currentYearDiff = 0;
   let previousYearDiff = 0;

   if (payYear === currentYear) {
      // Payment is in the same year, calculate months directly
      currentYearDiff = Math.max(0, currentMonth - pm);
   } else if (payYear < currentYear) {
      // Payment is in a previous year
      previousYearDiff = 12 - pm + 1; // Remaining months in the previous year (Including PM)
      currentYearDiff = currentMonth; // Months in the current year
   }
   let prevYearArrears=0;let curArrears=0;
if(previousYearDiff>0){
    prevYearArrears = (parseFloat(cr) * (previousYearDiff-1)) + parseFloat(current_arrears);
    // curArrears = (parseFloat(cr) * (currentYearDiff-1));
}
if(currentYearDiff>0){
    curArrears = (parseFloat(cr) * (currentYearDiff-1)) + parseFloat(current_arrears);
    // prevYearArrears = (parseFloat(cr) * (previousYearDiff-1));
}
   // Calculate arrears separately for current and previous year months
   
  

   return {
      currentYearDiff,
      previousYearDiff,
      currentYearArrears: curArrears.toFixed(2),
      previousYearArrears: prevYearArrears.toFixed(2),
   };
}
,


               
      async deleteReceipt(id){
        let result = confirm("Confirm delete?")
        if(result == true){
         this.Nic12 = this.addZeroPadding();
           await axios.delete("/singlerece/" +  id );
        //    this.allreceipt = (await axios.get('/pivbyidrow/' +this.Nic12+'/'+this.nic)).data
        this.allreceipt =  (await axios.get('/ownpiv/'+ this.nic +"/"+ this.sabhaCode)).data
        }
       },




       async getMatchCus(nic,s_code)
       {
           await axios.get('/customers/'+ nic+'/'+s_code);
       },
       checkShop(){
         if(this.incomeObj.headid[1]==='shoprent'){
            document.getElementById('shopdetail').style.display ='block';
           
            // document.getElementById('arreas').style.display ='block';
           

         this.shouldDisable = true
         this.incomeObj.des=""
         this.shopdid=0
         }else{
            this.incomeObj.des=""
                   this.incomeObj.amount="0.00";
                   this.incomeObj.stamp="0.00";
                   this.incomeObj.discounts="0.00";
                   this.incomeObj.vat="0";
                   this.fine="0";
                   this.shopdid=0;
            document.getElementById('grandtotalcur').style.display ='none';
        document.getElementById('grandtotal').style.display ='none';
        document.getElementById('shopdetail').style.display ='none';
            // document.getElementById('arreas').style.display ='none';
     
            this.shouldDisable = false
         
         document.getElementById('ifineseg').style.display ="none";
         document.getElementById('ifinesegpfinevote').style.display ="none";
         document.getElementById('ifinesegar').style.display ="none";
         document.getElementById('ifinesegpfine').style.display ="none";
         document.getElementById('ifinesegh1').style.display ="none";
         document.getElementById('ifinesegh2').style.display ="none";
         document.getElementById('ifinesegfvote').style.display ="none";
         document.getElementById('ifinesegvote').style.display ="none";
         document.getElementById('ifinesegfcyArreasvote').style.display ="none";
         document.getElementById('ifinesegcyArreas').style.display ="none";
         document.getElementById('ifinesegrentmonthly').style.display ="none";
         document.getElementById('ifinesegfrentvote').style.display ="none";
        //  document.getElementById('pivamount').style.display ="block";
         document.getElementById('pivdis').style.display ="block";
         document.getElementById('pivvat').style.display ="block";
         document.getElementById('pivstamp').style.display ="block";
                  

         }
        
       },
       async selectShops(shopid){
        // document.getElementById('fine').style.display ='block';
        document.getElementById('grandtotal').style.display ='block';
        document.getElementById('grandtotalcur').style.display ='block';
         document.getElementById('ifineseg').style.display ="block";
         document.getElementById('ifinesegpfine').style.display ="block";
         document.getElementById('ifinesegpfinevote').style.display ="block";
         document.getElementById('ifinesegar').style.display ="block";
         document.getElementById('ifinesegh1').style.display ="block";
         document.getElementById('ifinesegh2').style.display ="block";
         document.getElementById('ifinesegfvote').style.display ="block";
         document.getElementById('ifinesegvote').style.display ="block";
         document.getElementById('ifinesegfcyArreasvote').style.display ="block";
         document.getElementById('ifinesegcyArreas').style.display ="block";
         document.getElementById('ifinesegrentmonthly').style.display ="block";
        //  document.getElementById('pivamount').style.display ="none";
         document.getElementById('pivstamp').style.display ="none";
         document.getElementById('pivdis').style.display ="none";
         document.getElementById('pivvat').style.display ="none";
         document.getElementById('ifinesegfrentvote').style.display ="block";
        
        this.incomeObj.rentvote=this.incomeObj.headid[0];
          //shopdataid
         //  this.shopDetails= (await axios.get('/shopdataid/' +this.incomeObj.selectedShops)).data;
          this.shopDetails= (await axios.get('/shopdataid/' +shopid)).data;
          this.incomeObj.des= this.currentDate.getFullYear()+"-" + this.getMonthName(parseInt(this.currentDate.getMonth()+1))+" "  + this.shopDetails[0].place +" - "+ this.shopDetails[0].shop_number +" සඳහා " 
         
          this.shopList = (await axios.get('/shopdataid/' +shopid)).data
    //    this.total_arresrs = parseFloat(this.shopList[0].arrears)
    this.total_arresrs = this.DiffOfMonthstwoYear(this.shopList[0].pay_month,this.shopList[0].monthly_shop_rent,this.shopList[0].arrears,this.shopList[0].pay_year)

       let checkMonth1 = this.getPaymentOfMonth(this.shopList[0].pay_month, this.shopList[0].monthly_shop_rent,this.shopList[0].pay_year);
    //    if (!this.isGreaterThan10th) {
    let rentmon=parseFloat(this.shopList[0].monthly_shop_rent);
    this.totalAmountRent = parseFloat(checkMonth1)+parseFloat(this.total_arresrs.currentYearArrears);
           
                        if (this.shopList.length > 0 && rentmon > 0) {
                                const monthlyRent = parseFloat(this.shopList[0].monthly_shop_rent);
                                const fineRatePercentage = parseFloat(this.fineRate) / 100;
                                
                                let numarr = this.total_arresrs.currentYearArrears > 0 ? (parseFloat(this.total_arresrs.currentYearArrears) / rentmon) : 0;
                                let numarrpre = this.total_arresrs.previousYearArrears > 0 ? (parseFloat(this.total_arresrs.previousYearArrears) / rentmon) : 0;
                                
                                if (this.isGreaterThan10th && this.fineRate > 0 && checkMonth1 !== 0) {
                                    numarr += 1; // Extra fine if past the 10th
                                }

                                if(this.total_arresrs.currentYearArrears >0){
                                    this.fine = numarr > 0 ? monthlyRent * fineRatePercentage * numarr : 0;

                                }else{
                                    this.fine = numarr > 0 ? this.totalAmountRent * fineRatePercentage * numarr : 0;
                                }

                                  if(this.total_arresrs.previousYearArrears >0){
                                    this.prefineamount = numarrpre > 0 ? monthlyRent * fineRatePercentage * numarrpre : 0;

                                }else{
                                    this.prefineamount = numarrpre > 0 ? this.PretotalAmountRent * fineRatePercentage * numarrpre : 0;
                                }

                            } else {
                                this.fine = 0;
                                this.prefineamount=0; // Handle cases where shopList is empty or rentmon is zero
                            }
    this.totalAmountRent = parseFloat(checkMonth1)+parseFloat(this.total_arresrs.currentYearArrears)+parseFloat(this.fine);
                            
                            this.incomeObj.preFine=parseFloat(this.prefineamount).toFixed(2);
                           this.incomeObj.fineamount=parseFloat(this.fine).toFixed(2);
                           this.PretotalAmountRent=parseFloat(this.prefineamount)+parseFloat(this.total_arresrs.previousYearArrears);
                           this.fullTotalRent=parseFloat(this.PretotalAmountRent+this.totalAmountRent);
                            // let curArrears= this.DiffOfMonthstwoYear(this.shopList[0].pay_month,this.shopList[0].monthly_shop_rent,this.shopList[0].arrears,this.shopList[0].pay_year)

                        //    let amountnotfine=parseFloat(checkMonth1)+parseFloat(curArrears.currentYearArrears);
                        //    this.incomeObj.amount=amountnotfine.toFixed(2);
                           
                           let preArramount=this.DiffOfMonthstwoYear(this.shopList[0].pay_month,this.shopList[0].monthly_shop_rent,this.shopList[0].arrears,this.shopList[0].pay_year)
                           this.incomeObj.preArramount=preArramount.previousYearArrears;
                           this.incomeObj.cyArreas=preArramount.currentYearArrears;
                           this.incomeObj.rentmonthly=this.shopList[0].monthly_shop_rent;

         this.shopdid = shopid
       },

       resetCheckErr: function () {
           this.errObj.nicErr = [];
           this.errObj.nameErr = [];
           this.errObj.phoneErr = [];
           this.errObj.addErr = [];
       },
       checkEmptyErr: function () {
           for (var typeErr in this.errObj) {
               if (this.errObj[typeErr].length != 0) {
                   return false;
               }
           }
           return true;
       },
       checkForm: function () {
           this.resetCheckErr();
            // NIC validate

            if (!this.cusObj.nic) {
                this.errors.push("Entering a nic is required");
            }
            else {
                // if (this.cusObj.nic.length !==12)
                //     {
                //         if((this.cusObj.nic.charAt(9).toUpperCase() !== 'V') || (this.cusObj.nic.charAt(9).toLowerCase() !== 'v'))
                //           {
                //             this.errors.push('NIC must be valid');
                //           }
                          
                //     }
                if ( this.cusObj.nic.length !== 12 &&
                            !(
                                this.cusObj.nic.charAt(9).toUpperCase() === 'V' ||
                                this.cusObj.nic.charAt(9).toLowerCase() === 'v' ||
                                this.cusObj.nic.charAt(9).toUpperCase() === 'X' ||
                                this.cusObj.nic.charAt(9).toLowerCase() === 'x'
                            )
                        ) {
                            this.errors.push('NIC must be valid');
                        }
            
            }


           // Name validate
           if (!this.cusObj.name) {
               this.errObj.nameErr.push("Entering a name is required");
           }
          // else {
              // if (!/^[A-Za-z]+$/.test(this.cusObj.name.replace(/\s/g, ""))) {
              //     this.errObj.nameErr.push('A name can only contain letters');
              // }
          // }
          
           // Phone validate
           if (!this.cusObj.phone) {
               this.errObj.phoneErr.push('Entering phone number is required');
           }
           else{
                if(!/[0-9]{10}/.test(this.cusObj.phone)) {
                this.errObj.phoneErr.push('Phone numbers should contain 10 Digits, no spaces allowed');
                }
            }
  
             // Address Validate
             if (!this.cusObj.address) {
               this.errObj.addErr.push("Entering an address is required");
           }   

       },
      

       async handleSubmit1(e) {
           this.checkForm();

           if (!this.checkEmptyErr()) {
               e.preventDefault();
           } else {
               e.preventDefault();
               this.Nic12 = this.addZeroPadding();
               await this.getMatchCus(this.Nic12,this.sabha);
               if (this.matchCus) {
                   this.$refs.alert.showAlert('error', 'Something Went Wrong')

               }
               else {
                   let data = {
                     sabha_code : this.sabha,
                       cus_nic: this.Nic12,
                       cus_name: this.cusObj.name,
                       cus_contact: this.cusObj.phone,
                       cus_address: this.cusObj.address,

                       
                   }
         //           let sabhaCustomerData = {
                      
         //               cus_nic : this.cusObj.nic,

         //   };
                    await axios.post("/customers/", data)
                    this.$refs.alert.showAlert('success', 'User Added Successfully !')
                  
                  //  await axios.post('/sbha_cutomers/' , sabhaCustomerData);
                  
                   // document.getElementById("userForm").reset();
                 
                 
               }
           }
       },
       // second Form handle

       resetCheckErr2: function () {
           this.errIncomeObj.iheadErr = [];
           this.errIncomeObj.iDesErr = [];
           this.errIncomeObj.amountErr =[];
           this.errIncomeObj.stampErr = [];
           this.errIncomeObj.discErr = [];
           this.errIncomeObj.preArrvoteErr=[];
           this.errIncomeObj.preFinevoteErr=[];
           this.errIncomeObj.cyArreasErr=[];
           this.errIncomeObj.fineVoteErr=[];

       },
       checkEmptyErr2: function () {
           for (var typeErr in this.errIncomeObj) {
               if (this.errIncomeObj[typeErr].length != 0) {
                   return false;
               }
           }
           return true;
       },
       checkForm2: function () {
           this.resetCheckErr2();
           // income head Validate
           if (!this.incomeObj.headid) {
               this.errIncomeObj.iheadErr.push("Income head is required");
           }
           if (!this.incomeObj.des) {
               this.errIncomeObj.iDesErr.push("Description is required");
           }
           if (!this.incomeObj.amount) {
               this.errIncomeObj.amountErr.push("Amount is required");
           }
           if (!this.incomeObj.stamp) {
               this.errIncomeObj.stampErr.push("stamp fee is required");
           }
           if (!this.incomeObj.discounts) {
               this.errIncomeObj.discErr.push("discount required");
           }
           if (this.incomeObj.preArramount>0 && !this.incomeObj.preArrvote) {
               this.errIncomeObj.preArrvoteErr.push("Previous Arrears Vote is required");
           }
           if (this.incomeObj.preFine>0 && !this.incomeObj.preFinevote) {
               this.errIncomeObj.preFinevoteErr.push("Previous Fine Vote is required");
           }
           if (this.incomeObj.cyArreas>0 && !this.incomeObj.cyArreasvote) {
               this.errIncomeObj.cyArreasErr.push("Current Arrears Vote is required");
           }
           if (this.incomeObj.fineamount>0 && !this.incomeObj.finevote) {
               this.errIncomeObj.fineVoteErr.push("Current Fine Vote is required");
           }
       },
    
   },
   components: {
       VueBasicAlert
   }

};

</script>

<style scoped>
.ttable {
     
     width: 100%;
     border-width:2px;
     border-color : #130f40;
     /* border-style: solid; */
     font-size: 1.2rem;
     text-align: center;
     /* background-color :white; */
     /* padding-left: 3rem; */
      /* height: 3rem; */
      padding: 2%;
  }
  th  {
     text-align: center;
  }
  .ttable, th, td {
  border: 1px solid;
  }
  
input[type="button"] {
   background: none;
   color: inherit;
   border: none;
   padding: 0;
   font: inherit;
   cursor: pointer;
   outline: inherit;
}


hr {
   border-top: 3px solid #057835fa;
   width: 100%;
}





.menu-section {
   padding: 1rem 1%;
}

.menu-section .menu-tabs {
   /* margin-bottom: 30px; */
   flex: 0 0 100%;
   max-width: 100%;
   text-align: center;
   background: #5e5c5cda;
   /* background-color: #27ae60; Income Topic */
}

.menu-section .menu-tabs .menu-tab-item {
   display: inline-block;
   cursor: pointer;
   padding: 5px 30px;
   border-radius: 30%;
   font-size: 20px;
   /* color: rgb(19, 18, 18); heading color */
   color: whitesmoke;
   font-weight: 500;
   text-transform: capitalize;
   transition: all 0.3s ease;
   margin: 0;
}

.menu-section .menu-tabs .menu-tab-item p {
   padding: none;
   margin: none;
}

.menu-section .box-container {
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
   gap: 1.5rem;
   
}

.menu-section .box-container .box {
   border-radius: 0.5rem;
   position: relative;
   background: #f7f7f7;
   padding: 2rem;
   text-align: center;
   
}

.menu-section .box-container .box .fa-heart {
   position: absolute;
   top: 1.5rem;
   right: 1.5rem;
   font-size: 2.5rem;
   color: #666;
   cursor: pointer;
}

.menu-section .box-container .box .fa-heart:hover {
   color: #27ae60;
}

.menu-section .box-container .box .image {
   margin: 1rem 0;
}

.menu-section .box-container .box .image img {
   height: 15rem;
}

.menu-section .box-container .box .content h3 {
   font-size: 2rem;
   color: #130f40;
}

.menu-section .box-container .box .content .stars {
   padding: 1rem 0;
   font-size: 1.7rem;
}

.menu-section .box-container .box .content .stars i {
   color: gold;
}

.menu-section .box-container .box .content .stars span {
   color: #666;
}

.menu-section .box-container .box .content .desc p {
   font-size: 14px;
   margin: 0;
}

.menu-section .box-container .box .content .price {
   font-size: 2rem;
   color: #130f40;
}

.menu-section .box-container .box .content .price span {
   font-size: 1.5rem;
   color: #666;
   /* color: #911580; */
   text-decoration: line-through;
   
}

.menu-section .action-row {
   padding-top: 30px;
   width: 100%;
   text-align: center;
   font-size: 20px;
}

.menu-section .action-row .action-btn {
   background-color: #27ae60;
   padding: 3px;
   border: 2px solid #27ae60;
   border-radius: 30%;
   color: white;
}

.menu-section .action-row span {
   margin-right: 15px;
}

.menu-section .action-row span:hover {
   cursor: pointer;
}

.menu-section .action-row span.highlight {
   color: #f38609;
   
}

.menu-section .action-row span:first-of-type {
   margin-left: 15px;
}

.filter-drop-down {
   display: none;
}

@media (min-width: 576px) {

   .filter-heading,
   .filter-section {
       display: block !important;
   }
}

@media (max-width: 768px) {
   .menu-section .box-container {

       grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
       gap: 1rem;
        
   }

   .menu-section .box-container .box .content h3 {
       height: 4rem;
   }
}

@media (max-width: 576px) {

   .search-box,
   .filter-heading,
   .filter-section {
       width: auto;
   }

   .filter-option {
       width: 100%;
   }


   .menu-section .action-row {
       font-size: 16px !important;
   }

   .menu-section .action-row span {
       margin-right: 5px;
   }

   .menu-section .box-container .box .image img {
       height: 10rem;
   }

   .menu-section .box-container .box .desc p,
   .menu-section .box-container .box .content .stars {
       font-size: 10px !important;
   }

   .menu-section .box-container .box .content h3 {
       font-size: 14px !important;
       height: 28px;
   }
}
/* new */
.admin-container {
   background-color: #fff;
   /* height: 100vh; */
   padding: 2rem 9%;
   font-size: 16px;
}

.project-list>tbody>tr>td {
   padding: 12px 8px;
   font-size: small;
}

.project-list>tbody>tr>td .avatar {
   width: 22px;
   border: 1px solid #CCC;
}

.table-responsive {
   margin-top: 20vh;
   /* height: 300px; */
    padding: 8px 90px;
}

.action-btn,


.action-btn:hover {
   background-color: #27ae60;
}
.order-section form .row .input-box {
   width: 49%;
   padding: 1.8rem 0;
} 
/* newly added from income heads */
.register-container {
   padding: 1rem 1%;
}

.register-container .register-form-container {
   background: #fff;
   /* width: 100% !important; */
}

.register-container .register-form-container form {
   /* position: relative; */
   /* left: 40%; */
   /* transform: translate(-50%, 0%); */
   /* max-width: 70rem; */
   width: 100%;
   box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
   border: 0.1rem solid rgba(0, 0, 0, 0.2);
   padding: 1rem;
   border-radius: 0.5rem;
   animation: fadeUp 0.4s linear;
}

.register-container .register-form-container form h3 {
   padding-bottom: 1rem;
   font-size: 2rem;
   text-transform: uppercase;
   color: #130f40;
   margin: 0;
}

.register-container .register-form-container form .form-control {
    margin: 0.7rem 0;
   border-radius: 0.5rem;
   background: #f7f7f7;
   /* padding: 2rem 1.2rem; */
    font-size: 1.3rem; 
   color: #130f40;
   text-transform: none;
   width: 100%;
   border: bottom 1px solid; 
  
} 
.register-container .register-form-container form .form-control1 {
    margin: 0.7rem 0;
   border-radius: 0.5rem;
   background: #f7f7f7;
   /* padding: 2rem 1.2rem; */
    font-size: 1.3rem; 
   color: #130f40;
   text-transform: none;
   width: 100%;
   border: bottom 1px solid; 
} 

.register-container .register-form-container form label {
   font-size: 1.2rem;
   margin: 0;
   padding: 0;
   float:left;
}

.register-container .register-form-container form span {
   font-size: 18px;
   padding-left: 5px;
   padding-right: 40px;
   display: block; 
   overflow: hidden; 
   
}

.register-container .register-form-container form .btn {
   margin: 1rem 0;
   width: 10%;
   text-align: center;
   background-color : #af74a7;
   height: 30px;
   font-size: 1rem; 
}

.register-container .register-form-container form p {
   padding-top: 1rem;
   font-size: 1.5rem;
   color: #666;
   margin: 0;
}

.register-container .register-form-container form p a {
   color: #27ae60;
}

.register-container .register-form-container form p a:hover {
   color: #130f40;
   text-decoration: underline;
}

.register-container .register-form-container form .form-group {
   margin: 0;
}

.register-container .register-form-container form .form-group .error-mess {
   font-size: 1.5rem;
   position: relative;
   color: rgb(243, 47, 47);
   margin: 0;
   padding: 0;
}
.register-container .register-form-container form .container .error-mess {
   font-size: 1.5rem;
   position: relative;
   color: rgb(243, 47, 47);
   margin: 0;
   padding: 0;
}
/* shroff form container */
.shroff-container {
   background-color: #ffffff09;
   /* height: 50vh; */
   padding: 1rem 1%;
   /* font-size: 16px; */
}

/* .project-list>tbody>tr>td {
   padding: 12px 8px;
} */

.project-list>tbody>tr>td .avatar {
   width: 22px;
   border: 1px solid #CCC;
}

.table-responsive {
   margin-top: 8vh;
   height: 500px;
}

.action-btn,
.cancel-btn,


.action-btn {
   background-color: #0da9ef;
   margin-right: 10px;
}

.cancel-btn,
.paid-btn {
   background-color: red;
}

.action-btn:hover {
   background-color: #27ae60;
}
.order-section form .row .input-box {
   width: 49%;
   padding: 1.8rem 0;
} 

/* ongoing */
.shroff-container .shroff-form-container {
   background: #fff;
   /* width: 100% !important; */
   
}

.shroff-container .shroff-form-container form {
   /* position: relative;
   left: 40%;
   transform: translate(-50%, 0%); */
   /* max-width: 70rem; */
   width: 100%;
   box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
   border: 0.1rem solid rgba(0, 0, 0, 0.2);
   padding: 3.5rem;
   border-radius: 0.5rem;
   animation: fadeUp 0.4s linear;
}

.shroff-container .shroff-form-container form h3 {
   padding-bottom: 1rem;
   font-size: 2rem;
   text-transform: uppercase;
   color: #130f40;
   margin: 0;
}

.shroff-container .shroff-form-container form .form-control {
    margin: 0.7rem 0;
   border-radius: 0.5rem;
   background: #f7f7f7;
   /* padding: 2rem 1.2rem; */
    font-size: 1.3rem; 
   color: #130f40;
   text-transform: none;
   width: 100%;
   border: margin-bottom; 
} 

.shroff-container .shroff-form-container form label {
    font-size: 1.2rem;
   margin: 0;
   padding: 0;
   float:left;
}

.shroff-container .shroff-form-container form span {
   font-size: 18px;
   padding-left: 5px;
   padding-right: 40px;
}

.shroff-container .shroff-form-container form .btn {
   /* margin: 1rem 0; */
   width: 25%;
   text-align: center;
   font-size: small;
   background-color : #af74a7;
    height: 3rem;
  
   
}
.shroff-container .shroff-form-container form .bbtn {
   /* margin: 1rem 0; */
   width: 100%;
   text-align: center;
   font-size: small;
   background-color : #af74a7;
    height: 3rem;
}
.shroff-container .shroff-form-container form .table {
   /* margin: 1rem 0; */
   width: 100%;
   text-align: center;
   font-size: small;
   background-color : #e9e4e9;
    /* height: 3rem; */
   
}
.shroff-container .shroff-form-container form .Addbtn {
   /* margin: 1rem 0; */
   width: 5rem;
   /* height: 2rem; */
   /* text-align: center; */
   /* background-color : #af74a7; */
   /* vertical-align: bottom; */
   /* align-items: bottom; */
   padding-top: 1.9rem;
   padding-left: 3rem;
   
}

.shroff-container .shroff-form-container form p {
   padding-top: 1rem;
   font-size: 1.5rem;
   color: #666;
   margin: 0;
}

.shroff-container .shroff-form-container form p a {
   color: #27ae60;
}

.shroff-container .shroff-form-container form p a:hover {
   color: #130f40;
   text-decoration: underline;
}

.shroff-container .shroff-form-container form .form-group {
   margin: 0;
}

.shroff-container .shroff-form-container form .form-group .error-mess {
   font-size: 1.5rem;
   position: relative;
   color: rgb(243, 47, 47);
   margin: 0;
   padding: 2rem 1.2rem;
}
.project-list>tbody>tr>td {
   padding: 12px 8px;
}

.project-list>tbody>tr>td .avatar {
   width: 22px;
   border: 1px solid #CCC;
}

.table-responsive {
   margin-top: 8vh;
}

.action-btn,
.cancel-btn,
.paid-btn {
   width: 100px;
   height: 25px;
   color: white;
   text-transform: capitalize;
}

.action-btn {
   background-color: #0da9ef;
   margin-right: 10px;
}

.cancel-btn,
.paid-btn {
   background-color: red;
}

.action-btn:hover {
   background-color: #27ae60;
}

</style>
