<template>
     <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
    <div class="add-container">
        <div class="add-form-container">
            <form id="addMarketsForm" @submit="handleSubmit" novalidate autocomplete="off">
                <div class="heading">
                    <b><h3>Add Market Places</h3></b>

                </div>
                
                 
                <div class="form-group">
                    <label for="iCode">Market/Street/Place:
                    </label>
                    <input type="text" name="iAcc"  id="iAcc" class="form-control"
                    v-model="shopObj.name"   />
                    <p class="error-mess" v-if="errObj.nameErr.length > 0">{{ errObj.nameErr[0] }}</p>
                </div>


                <div class="form-group">
                    <input type="submit" value="Add" class="btn" />
                  
                </div>
<!-- start -->
                <div class="row">
                            <table id="dtBasicExample" class="ttable table-striped table-bordered" >
                            <thead>
                                <tr>   
                                    <th>Index</th>
                                    <th>Market/Street/Place</th>
                                    <th>Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for=" (s,index) in paginatedData1" :key="index">
                                    <td>{{ (currentPage1 - 1) * 10 + index + 1 }}</td>
                                    <td>{{ s.place  }}</td>
                                    <td><input type="button" @click="deletePlace(s.ms_id)" value="X" class=" btn"></td>
                                    
                                </tr>
                            </tbody>
                        </table>
                        <button class="fas fa-backward" @click="prevPage1($event)" :disabled="currentPage1 === 1"></button>
                            <span>{{ currentPage1 }} / {{ totalPages1 }}</span>
                            <button class="fas fa-forward" @click="nextPage1($event)" :disabled="currentPage1 === totalPages1"></button>
                        </div>
<!-- end -->
            </form>
        </div>
    </div>
<div class="Shop-container">
       <div class="Shop-form-container">
           <form id="addShopsForm"  novalidate autocomplete="off">
                <div class="heading">
                    <b><h3>Add Shops</h3></b>
                </div>
               
               <div class="row">
                   
               <div class="col-sm-4">
                   <label for="Splace">Market/Street/Place 
                   </label>
                   <!-- <input type="text" name="Splace" id="Splace" class="form-control" 
                       v-model="ShpnumObj.place" /> -->
                       <div><select v-model="ShpnumObj.place" id="Splace" class="form-control" >
                            <option value="" selected disabled>
                                 Choose
                             </option>
                            <option v-for="m in marketplaces" :key="m.ms_id" id="Hid"  
                            v-bind:value =m.ms_id>
                               <div>{{m.place }}</div> 
                            </option>
                        </select>
                     </div> 

                   <p class="error-mess" v-if="errShpnumObj.placeErr.length > 0">{{ errShpnumObj.placeErr[0] }}</p>
               </div>
               <div class="col-sm-4">
                   <label for="Snum">Shop Number:
                   </label>
                   <input type="text" name="Snum" id="Snum" class="form-control" 
                     v-model="ShpnumObj.shopnum" />
                    <p class="error-mess" v-if="errShpnumObj.shopnumErr.length > 0">{{ errShpnumObj.shopnumErr[0] }}</p>
               </div>
               <div class="Addbtn col-sm-4">
                   <label for="s">
                   </label>
                   <input type="button" value="Add" id="Addpay" class=" btn" @click="addToTable"  /> 
               </div>
           </div>
           <br>
           <div class="row">
               <table id="dtBasicExample" class="ttable table-striped table-bordered" >
               <thead>
                   <tr>   
                        <th>Index</th>
                       <th>Market/Street/Place</th>
                       <th>Shop Number</th>
                       <th>Remove</th>
                   </tr>
               </thead>
               <tbody>
                   <tr v-for=" (s,index) in paginatedData" :key="index">
                    <td>{{ (currentPage - 1) * 50 + index + 1 }}</td>
                       <td>{{ s.place  }}</td>
                       <td>{{ s.shop_no  }}</td>
                       <td><input type="button" @click="deleteShop(s.shop_place_id,s.ms_id,s.shop_no)" value="X" class=" btn"></td>
                     <!-- shop_place 
                     <td><input type="button" @click="deleteShop(s.shop_place_id,s.shop_number)" value="X" class=" btn"></td>-->
                   </tr>
               </tbody>
           </table>
           <button class="fas fa-backward" @click="prevPage($event)" :disabled="currentPage === 1"></button>
             <span>{{ currentPage }} / {{ totalPages }}</span>
            <button class="fas fa-forward" @click="nextPage($event)" :disabled="currentPage === totalPages"></button>
           </div>
           </form>
       </div>
</div>
<!-- end -->

</template>

<script>

import axios from 'axios';
import VueBasicAlert from 'vue-basic-alert'
import { mapMutations } from "vuex";
import { mapState } from "vuex";


export default {
   name: "ShopDetails",

   data() {
       return {
            shopObj : { name: ""},
           errObj : {nameErr:[]},
           matchCus : undefined,
           nic:"",
           sabha: "",
           userLevel:"",
           ShpnumObj : { place:"",shopnum:"" },
           errShpnumObj:{shopnumErr:[], placeErr:[]},
           sabhaIncomeHead : undefined,
           marketplaces:[],
           allShops:[],
           employeedetail:[],
           itemsPerPage: 50,
           itemsPerPage1: 5,
            currentPage: 1,
            currentPage1: 1,
            marketplacesall:[]
       };
   },
   created() {
       this.getDataFromSessionStorage()
     
     
   },
   computed:{
       ...mapState(["user"]),
       paginatedData() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    
    // Check if this.data is an array before using slice method
    if (Array.isArray(this.allShops)) {
      return this.allShops.slice(startIndex, endIndex);
    } else {
      // Return empty array or handle loading state
      return [];
    }
  },
  totalPages() {
      return Math.ceil(this.allShops.length / this.itemsPerPage);
    },
// first table
    paginatedData1() {
    const startIndex1 = (this.currentPage1 - 1) * this.itemsPerPage1;
    const endIndex1 = startIndex1 + this.itemsPerPage1;
    
    // Check if this.data is an array before using slice method
    if (Array.isArray(this.marketplacesall)) {
      return this.marketplacesall.slice(startIndex1, endIndex1);
    } else {
      // Return empty array or handle loading state
      return [];
    }
  },
  totalPages1() {
      return Math.ceil(this.marketplacesall.length / this.itemsPerPage1);
    },
   },



   methods: {
       ...mapMutations(["setUser"]),

       async getDataFromSessionStorage(){
           const data = JSON.parse(sessionStorage.getItem('userData'))
           if(data){
                           this.nic = data.nic 
                           this.sabha = data.sabha
                           this.userLevel =data.userLevel
       

                       }
           let sabhaCode = this.sabha
           
           this.marketplaces = (await axios.get('/marketstreet/'+ sabhaCode)).data
           this.allShops = (await axios.get('/shopsosb/' +this.sabha)).data
           this.marketplacesall = (await axios.get('/marketstreet/' +this.sabha)).data
       },
      
       nextPage(event) {
  if (this.currentPage < this.totalPages) {
    this.currentPage++;
  }
  // Prevent default form submission behavior
  event.preventDefault();
},
prevPage(event) {
  if (this.currentPage > 1) {
    this.currentPage--;
  }
  // Prevent default form submission behavior
  event.preventDefault();
},
// ///////////////////////////////////////////
nextPage1(event) {
  if (this.currentPage1 < this.totalPages1) {
    this.currentPage1++;
  }
  // Prevent default form submission behavior
  event.preventDefault();
},
prevPage1(event) {
  if (this.currentPage1 > 1) {
    this.currentPage1--;
  }
  // Prevent default form submission behavior
  event.preventDefault();
},


async deleteShop(id,place,sid){
    let result = confirm(" Are you Sure you want to Delete?")
        if(result == true){
           await axios.delete("/deletshop/" +  id );
           await axios.delete("/deleteboth/"+place+"/"+sid +"/"+this.sabha );
           this.$refs.alert.showAlert('success', 'Shop deleted Successfully !')
           this.allShops = (await axios.get('/shopsosb/' +this.sabha)).data
        }
       },
async deletePlace(id){
    let result = confirm("මෙම ස්ථානය ඉවත් කිරීමේදී එයට අයත් සියළු කඩ කාමරද ඉවත් වේ. ඔබට එය ඉවත් කිරීමට අවශ්‍යද? | by deleting this Market plcae you will delete all shops assigned to this Market palce also. Are you Sure you want to Delete?")
        if(result == true){
    await axios.delete("/delmarkstreet/"+id ); 
    await axios.delete("/delshopbymarkstreet/"+id );
    await axios.delete("/delsabhashopplace/"+id );
    this.marketplacesall = (await axios.get('/marketstreet/' +this.sabha)).data
    this.allShops = (await axios.get('/shopsosb/' +this.sabha)).data
    this.$refs.alert.showAlert('success', 'Market Place Deleted Successfully !')
        }
},

  async nextClick(){
   sessionStorage.setItem("cus_nic",JSON.stringify(this.cusObj.nic) )

   await this.getMatchUser(this.nic); 
           if(this.matchUser.status ==1)
   {
       this.$router.push("/Invoice");
       }else {
           this.$router.push("/ShroffDashboard");
               }
  },
       async getMatchCus(nic)
       {
           await axios.get('/customers/' + nic);
       },

       resetCheckErr: function () {
           this.errObj.nameErr = [];
       },
       checkEmptyErr: function () {
           for (var typeErr in this.errObj) {
               if (this.errObj[typeErr].length != 0) {
                   return false;
               }
           }
           return true;
       },
       checkForm: function () {
           this.resetCheckErr();
           
           // Name validate
           if (!this.shopObj.name) {
               this.errObj.nameErr.push("Entering a name is required");
           }
          

       },
      
       async handleSubmit(e) {
            this.checkForm();


            if (!this.checkEmptyErr()) {
                e.preventDefault();
            } else {
                e.preventDefault();
                // await this.matchAccNum(this.bankObj.accNum);
                // if (this.matchAcc) {
                //     this.errorObj.bankErr.push("Market Place already exist")
                // }
                // else {
                    let data = {
                       
                        sb_code : this.sabha ,
                        place: this.shopObj.name,
                        
                    }
                    await axios.post("/marketstreet/", data)
                    this.$refs.alert.showAlert('success', 'Market Place Added Successfully !')
                    document.getElementById("addMarketsForm").reset();
                    this.marketplaces = (await axios.get('/marketstreet/'+this.sabha)).data
                // }
            }
        },
       // second Form handle

       resetCheckErr2: function () {
        this.errShpnumObj.shopnumErr =[];
        this.errShpnumObj.placeErr = [];

       },
       checkEmptyErr2: function () {
           for (var typeErr in this.errShpnumObj) {
               if (this.errShpnumObj[typeErr].length != 0) {
                   return false;
               }
           }
           return true;
       },
       checkForm2: function () {
           this.resetCheckErr2();
           if (!this.ShpnumObj.shopnum) {
               this.errShpnumObj.shopnumErr.push("Shop number is required");
           }
           if (!this.ShpnumObj.place) {
               this.errShpnumObj.placeErr.push("Place is required");
           }
           
       },
       async addToTable(){
           this.checkForm2();

//    if (!this.checkEmptyErr2()) {
//        e.preventDefault2();
//    } else {
//        e.preventDefault2();
       
          let data ={
                sabha_code  : this.sabha,
                shop_no  : this.ShpnumObj.shopnum,
                ms_id  : this.ShpnumObj.place,

                 }
                  await axios.post("/saveshop/", data)
                  this.$refs.alert.showAlert('success', 'Shop Added Successfully !')
                  this.allShops = (await axios.get('/shopsosb/' +this.sabha)).data
                //   this.allreceipt = (await axios.get('/receiptrow/' +this.cusObj.nic)).data
                  //clear 2nd form values
                  this.ShpnumObj.place="",
                  this.ShpnumObj.shopnum=""
               }
                  
    //   },

   },
   components: {
        VueBasicAlert
    }

};

</script>

<style scoped>
 .ttable {
     
     width: 100%;
     border-width:1px;
     border-color : #130f40;
     /* border-style: solid; */
     font-size: 0.9rem;
     /* background-color :white; */
     /* padding-left: 3rem; */
      /* height: 3rem; */
      border-collapse: collapse;
 }
 th  {
     text-align: center;
}
.ttable, th, td {
border: 1px solid;
text-align: center;
}
input[type="button"] {
   background: none;
   color: inherit;
   border: none;
   padding: 0;
   font: inherit;
   cursor: pointer;
   outline: inherit;
}

/* new */

.project-list>tbody>tr>td {
   padding: 12px 8px;
   font-size: small;
}

.project-list>tbody>tr>td .avatar {
   width: 22px;
   border: 1px solid #CCC;
}


/* shroff form container */
.Shop-container {
   background-color: #ffffff09;
   /* height: 50vh; */
   padding: 2rem 9%;
   font-size: 16px;
}

.Shop-list>tbody>tr>td {
   padding: 12px 8px;
}

.project-list>tbody>tr>td .avatar {
   width: 22px;
   border: 1px solid #CCC;
}
/* newly added from income heads */
.Shop-container {
   padding: 2rem 9%;
}
/* ongoing */
.Shop-container .Shop-form-container {
   background: #fff;
   /* width: 100% !important; */
   
}

.Shop-container .Shop-form-container form {
   /* position: relative;
   left: 40%;
   transform: translate(-50%, 0%); */
   /* max-width: 70rem; */
   width: 100%;
   box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
   border: 0.1rem solid rgba(0, 0, 0, 0.2);
   padding: 2rem;
   border-radius: 0.5rem;
   animation: fadeUp 0.4s linear;
   
}

.Shop-container .Shop-form-container form h3 {
   padding-bottom: 1rem;
   font-size: 2rem;
   text-transform: uppercase;
   color: #130f40;
   margin: 0;
}

.Shop-container .Shop-form-container form .form-control {
    margin: 0.7rem 0;
   border-radius: 0.5rem;
   background: #f7f7f7;
   /* padding: 2rem 1.2rem; */
    font-size: 1.3rem; 
   color: #130f40;
   text-transform: none;
   width: 100%;
   border: margin-bottom; 
} 

.Shop-container .Shop-form-container form label {
    font-size: 1.2rem;
   margin: 0;
   padding: 0;
   float:left;
}

.Shop-container .Shop-form-container form span {
   font-size: 18px;
   padding-left: 5px;
   padding-right: 40px;
}

.Shop-container .Shop-form-container form .btn {
   /* margin: 1rem 0; */
   width: 25%;
   text-align: center;
   font-size: small;
   background-color : #032a2c;
    height: 3rem;
    color: #fffbfb;
  
   
}
/* .Shop-container .Shop-form-container form .table {
   margin: 1rem 0;
   width: 100%;
   text-align: center;
   font-size: small;
   background-color : #e9e4e9;
    height: 3rem;
   
} */
.Shop-container .Shop-form-container form .Addbtn {
   /* margin: 1rem 0; */
   width: 5rem;
   /* height: 2rem; */
   /* text-align: center; */
   /* background-color : #af74a7; */
   /* vertical-align: bottom; */
   /* align-items: bottom; */
   padding-top: 1.9rem;
   padding-left: 3rem;
   
}

.Shop-container .Shop-form-container form p {
   padding-top: 1rem;
   font-size: 1.5rem;
   color: rgb(243, 47, 47);
   margin: 0;
}

.Shop-container .Shop-form-container form p a {
   color: #27ae60;
}

.Shop-container .Shop-form-container form p a:hover {
   color: #130f40;
   text-decoration: underline;
}

.Shop-container .Shop-form-container form .form-group {
   margin: 0;
}

.Shop-container .Shop-form-container form .form-group .error-mess {
   font-size: 1.5rem;
   position: relative;
   color: rgb(243, 47, 47);
   margin: 0;
   padding: 2rem 1.2rem;
}
/* .project-list>tbody>tr>td {
   padding: 12px 8px;
}

.project-list>tbody>tr>td .avatar {
   width: 22px;
   border: 1px solid #CCC;
} */
.add-container {
    padding: 2rem 9%;
    
}

.add-container .add-form-container {
    background: #fff;

}

.add-container .add-form-container form {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    /* max-width: 70rem; */
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 2rem;
    border-radius: 0.5rem;
    animation: fadeUp 0.4s linear;
}

.add-container .add-form-container form h3 {
    padding-bottom: 1rem;
    font-size: 2rem;
    text-transform: uppercase;
    color: #130f40;
    margin: 0;
}

.add-container .add-form-container form .form-control {
     margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
     font-size: 1.6rem; 
    color: #130f40;
    text-transform: none;
    width: 100%;
    border: margin-bottom; 
}  

.add-container .add-form-container form label {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
}

.add-container .add-form-container form span {
    font-size: 18px;
    padding-left: 5px;
    padding-right: 40px;
     color: #4e0707;
}

.add-container .add-form-container form .btn {
    margin: 1rem 0;
    width: 25%;
    text-align: center;
    /* background-color: #af74a7 ; */
    align-content: center;
    background-color : #032a2c;
  
    color: #e9e4e9;
}

.add-container .add-form-container form p {
    padding-top: 1rem;
    font-size: 1.5rem;
    color: #666;
    margin: 0;
}

.add-container .add-form-container form p a {
    color: #27ae60;
}

.add-container .add-form-container form p a:hover {
    color: #130f40;
    text-decoration: underline;
}

.add-container .add-form-container form .form-group {
    margin: 0;
}

.add-container .add-form-container form .form-group .error-mess {
    font-size: 1.5rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
}

</style>
